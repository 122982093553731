
import { defineComponent, ref } from 'vue';
import ManagementTab from '../atoms/ManagementTab.vue';
import EmployeesTable from './tables/EmployeesTable.vue';
import FireExtinguisherTable from './tables/FireExtinguisherTable.vue';
import ResidentsTable from './tables/ResidentsTable.vue';
import ResidentsInactiveTable from './tables/ResidentsInactiveTable.vue';
import GasCylindersTable from './tables/GasCylindersTable.vue';
import PetsTable from './tables/PetsTable.vue'
export default defineComponent({
    props: {
        communityId: {
            type: Number,
            default: 0,
        },
    },
    setup() {
        const showEmployees = ref(true);
        const showResidents = ref(false);
        const showResidentsInactive = ref(false);
        const showFireExtinguishers = ref(false);
        const showGasCylinder = ref(false);
        const showHistory = ref(false);

        function turnOffAll() {
            showEmployees.value = false
            showResidents.value = false
            showResidentsInactive.value = false
            showFireExtinguishers.value = false
            showGasCylinder.value = false
            showHistory.value = false
        }
        return {
            turnOffAll,
            showResidentsInactive,
            showEmployees,
            showResidents,
            showFireExtinguishers,
            showGasCylinder,
            showHistory,
        };
    },
    components: { ResidentsInactiveTable, ManagementTab, EmployeesTable, ResidentsTable, FireExtinguisherTable, PetsTable, GasCylindersTable }
})
