import { User } from "../auth/types";
// import { default } from '../../components/general/atoms/Alert.vue';

export interface BaseCommunity {
  id: number;
  name: string;
  description: string;
  address: string;
  tuhmbnail?: string | null;
  rut: string;
  businessName: string;
  countyId: number;
}

export interface BaseForm {
  answer: [];
  form: {
    description: string;
    id: number;
    questions: Question[];
  };
}

export class Question {
  constructor(
    public formId: number | undefined = undefined,
    public content: string | undefined = undefined,
    public index: number | undefined = undefined,
    public id: number | undefined = undefined,
    public isCondition: boolean | undefined = undefined,
    public answerType: number | undefined = undefined,
    public answerTypeFront: string | undefined = undefined,
    public responseForm: string | undefined = undefined,
    public answer: any | undefined = undefined
  ) {}
}
export interface UserCreate {
  firstName: string | undefined;
  lastName: string | undefined;
  phone: string | undefined;
  email: string | undefined;
  dni: string | undefined;
  password: string;
  re_password: string;
}

export interface userCommunityBase {
  position: string;
  userRole: number;
  isActive: boolean;
  isCommitteeMember: boolean;
  isFab: boolean;
  communityId: number;
}
export interface unitBase {
  id?: number;
  floor: number;
  address: string;
  blockId: number;
  establishmentType: number;
}

export class Unit implements unitBase {
  constructor(
    public id: number,
    public floor: number,
    public address: string,
    public blockId: number,
    public establishmentType: number
  ) {}
}
export interface userCreate {
  communityUserInfo: userCommunityBase;
  userInfo: userBase;
  unitsInfo: unitBase[];
  warehouses: string[];
  parkingSpots: string[];
  unitsIds?: number[];
}

export interface userBase {
  firstName: string;
  lastName: string;
  profession: string;
  age: number;
  email: string;
  phone: string;
  dni: string;
  hasReducedCapacity: boolean;
  reducedCapacityDetails: string;
}

export interface userInfo {
  [x: string]: any;
  userId: number;
  position: string;
  parkingSpace: string;
  warehouseSpace: string;
  userRole: number;
  isActive: boolean;
  isCommitteeMember: boolean;
  committeePosition: string;
  isFab: boolean;
  communityId: number;
  id: number;
  user: userBase;
  units: unitBase[];
}

export class WorkerInfo {
  constructor(
    public user_info: {
      firstName?: string;
      lastName?: string;
      phone?: string;
      email?: string;
    },
    public communityUserEdit: {
      position?: string;
    }
  ) {}
}

export class CommunitySummary implements BaseCommunity {
  id!: number;
  name!: string;
  description!: string;
  address!: string;
  users!: number;
  blocks!: number;
  units!: number;
  notifications!: number;
  thumbnail?: string | null;
  rut!: string;
  businessName!: string;
  countyId!: number;
  isHovered!: boolean;
}

export class CreateCommunity {
  constructor(
    public rut: string | undefined = undefined,
    public name: string | undefined = undefined,
    public description: string | undefined = undefined,
    public address: string | undefined = undefined,
    public thumbnail?: File | null,
    public businessName: string | undefined = undefined,
    public countyId: number | undefined = undefined
  ) {}
}

class UserInfo implements User {
  constructor(
    public id: number | undefined = undefined,
    public firstName: string | undefined = undefined,
    public lastName: string | undefined = undefined,
    public phone: string | undefined = undefined,
    public email: string | undefined = undefined,
    public dni: string | undefined = undefined
  ) {}
}

export class updateState {
  constructor(public community_user_list: number[], public status: boolean) {}
}

export class UserCommunity {
  constructor(
    public user: UserInfo,
    public userRole?: number,
    public warehouseSpace?: string,
    public parkingSpace?: string
  ) {}
}

export class BlockCommunity {
  constructor(
    public totalVisitorParkingSpots: number | undefined = undefined,
    public totalParkingSpots: number | undefined = undefined,
    public totalWarehouseSpaces: number | undefined = undefined,
    public totalCommercialPremises: number | undefined = undefined,
    public totalUndegroundLevels: number | undefined = undefined,
    public gasType: string | undefined = undefined,
    public municipalAcceptanceDate: Date | undefined = undefined,
    public constructionYear: number | undefined = undefined,
    public structuralCalculations: string | undefined = undefined,
    public constructionCompany: string | undefined = undefined,
    public realStateAgency: string | undefined = undefined,
    public rut: string | undefined = undefined,
    public companyName: string | undefined = undefined,
    public communityId: number | undefined = undefined,
    public blockId: number | undefined = undefined,
    public id: number | undefined = undefined,
    public community_id: number | undefined = undefined,
    public name: string | undefined = undefined,
    public address: string | undefined = undefined,
    public totalFloors: number | undefined = undefined,
    public totalUnits: number | undefined = undefined,
    public thumbnail?: File | null,
    public isHovered?: boolean | false,
    public dynamicLink: string | undefined = undefined
  ) {}
}

export class UserCommunityData {
  constructor(public user: UserCommunity, public address: string) {}
}

export class Resident implements User {
  id!: number;
  firstName!: string;
  lastName!: string;
  phone!: string;
  email!: string;
  dni!: string | null;
  profession!: string | null;
  unit!: string | null;
  parking!: string | null;
  storage!: string | null;
  role!: number | null;
  fab!: boolean;

  get fullName() {
    return this.firstName + " " + this.lastName;
  }

  get isAdmin() {
    return this.role == 3;
  }

  get isConcierge() {
    return this.role == 2;
  }
}

export class Pets {
  id!: number;
  petType!: number;
}

export interface FireExtinguisherBase {
  quantity: number;
  extinguisherType: number;
  maintenance: string;
  blockId: number;
}
export interface FireExtinguisherSnakeCase {
  description: string;
  extinguisher_type: number;
  expiration_date: Date;
  certificate: boolean;
  rechargeable: boolean;
  block_id: number;
}

export class FireExtinguisher implements FireExtinguisherBase {
  constructor(
    public id: number,
    public quantity: number,
    public extinguisherType: number,
    public maintenance: string,
    public blockId: number
  ) {}
}

export class FireExtinguisherUpdate {
  constructor(
    public quantity: number,
    public extinguisherType: number,
    public maintenance: string
  ) {}
}

export class FireExtinguisherDelete {
  constructor(public id: number) {}
}

export interface GasCylinderBase {
  charge: number;
  quantity: number;
  unitId: number;
}
export class GasCylinder implements GasCylinderBase {
  constructor(
    public id: number,
    public charge: number,
    public quantity: number,
    public unitId: number
  ) {}
}

export class GasCylinderUpdate {
  constructor(public charge: number, public quantity: number) {}
}

export class GasCylinderDelete {
  constructor(public id: number) {}
}

export interface BlockBase {
  communityId: number;
  name: string;
  address: string;
  totalFloors: number;
  totalUnits: number;
  formId?: number;
  answers?: string[];
}

export class Block implements BlockBase {
  constructor(
    public id: number,
    public communityId: number,
    public name: string,
    public address: string,
    public totalFloors: number,
    public totalUnits: number,
    public formId?: number,
    public answers?: string[]
  ) {}
}

export class BlockWithOutId {
  constructor(
    public community_id: number,
    public name: string,
    public address: string,
    public total_floors: number,
    public total_units: number,
    public formId?: number,
    public answers?: string[]
  ) {}
}

export interface BaseQuery {
  id: number;
  question: string;
  answer: string;
  communityId: number;
}

export class Query implements BaseQuery {
  id!: number;
  question!: string;
  answer!: string;
  communityId!: number;
}

export class QueryWithOutId {
  question!: string;
  answer!: string;
}

export interface BaseAccessControl {
  name: string;
  description: string;
}

export interface AccessControl extends BaseAccessControl {
  community_id: number;
  access_control_id: number;
}

export interface AccessControlWithId extends BaseAccessControl {
  id: number;
}

export class Users {
  constructor(
    public userId: number,
    public position: string,
    public parkingSpace: string,
    public warehouseSpace: string,
    public userRole: number,
    public isActive: boolean,
    public isCommitteeMember: boolean,
    public committeePosition: string,
    public isFab: boolean,
    public communityId: number,
    public id: number,
    public user: userBase,
    public communityUserUnits: [
      {
        unitId: number;
        communityUserId: number;
        id: number;
        unit: unitBase;
        address: string;
      }
    ],
    public parkingSpots: [
      {
        communityUserId: number;
        id: number;
        description: string;
      }
    ],
    public warehouses: [
      {
        commimunityUserId: number;
        description: string;
        id: number;
      }
    ]
  ) {}
}

export class updateUsers {
  constructor(
    public userUpdate: {
      firstName: string;
      lastName: string;
      profession: string;
      age: number;
      email: string;
      phone: string;
      hasReducedCapacity: boolean;
      reducedCapacityDetails: string;
    },
    public communityUserEdit: {
      userId: number;
      communityId: number;
      userRole: number;
      position: string;
      parkingSpace: string;
      warehouseSpace: string;
      isCommitteeMember: boolean;
      committeePosition: string;
      isFab: boolean;
    }
  ) // public communityUnitEdit: {
  //     unitId: number,
  //     communityUserId: number,
  //     address: string
  // }
  {}
}

export class updateUsersDiminished {
  constructor(
    public userUpdate: {
      hasReducedCapacity: boolean;
      reducedCapacityDetails: string;
    }
  ) {}
}
export class FormattedDataResident {
  constructor(
    public userId: number,
    public position: string,
    public parkingSpots: string,
    public warehouseSpace: string,
    public userRole: number,
    public isActive: boolean,
    public isCommitteMember: boolean,
    public committePosition: string,
    public isFab: boolean,
    public communityId: number,
    public id: number,
    public firsName: string,
    public lastName: string,
    public profession: string,
    public age: number,
    public phone: string,
    public hasReducedCapacity: boolean,
    public reducedCapacityDetails: string,
    public email: string,
    public rut: string,
    public userInCommunity: number,
    public floor?: number | undefined,
    public address?: string | undefined,
    public establishments?: string | undefined,
    public idUnit?: number | undefined,
    public units = [
      {
        unitId: 0,
        communityUserId: 0,
        id: 0,
        unit: {
          floor: 0,
          address: "",
          establishmentType: 0,
        },
      },
    ],
    public parkingSpotsAux = [
      {
        communityUserId: 0,
        id: 0,
        description: "",
      },
    ],
    public wareHouse = [
      {
        commimunityUserId: 0,
        description: "",
        id: 0,
      },
    ]
  ) {}
}

export class InstructionType {
  constructor(public instructionSetType: string) {}
}

export class Instructions {
  constructor(
    public instruction: {
      content: string;
      emergencyTypeId: number;
      id: number;
      intructionSetType: string;
    },
    public instructionDetails: string[]
  ) {}
}
