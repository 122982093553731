import { User, UserSession, UserCommunity } from "@/services/auth/types";
import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", {
  state: () => {
    const localStorageUser = localStorage.getItem("user");
    const localStorageUserSession = localStorage.getItem("userSession");
    const localStorageUserCommunity = localStorage.getItem("userCommunity");

    let user: User | null = null;
    let userSession: UserSession | null = null;
    let userCommunity: UserCommunity | null = null;

    if (localStorageUser) {
      user = JSON.parse(localStorageUser);
    }
    if (localStorageUserSession) {
      userSession = JSON.parse(localStorageUserSession);
    }
    if (localStorageUserCommunity) {
      userCommunity = JSON.parse(localStorageUserCommunity);
    }

    return {
      token: localStorage.getItem("token"),
      userSession: userSession,
      user: user,
      userCommunity: userCommunity,
    };
  },

  actions: {
    setToken(token: string) {
      this.token = token;
      localStorage.setItem("token", token);
    },

    setUser(user: User) {
      this.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    setUserSession(userSession: UserSession) {
      this.userSession = userSession;
      localStorage.setItem("userSession", JSON.stringify(userSession));
    },
    setCommunityUser(userCommunity: UserCommunity) {
      this.userCommunity = userCommunity;
      localStorage.setItem("userCommunity", JSON.stringify(userCommunity));
    },
    logout() {
      this.token = null;
      this.user = null;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("userSession");
      // localStorage.removeItem("userId");

    },
  },

  getters: {
    userIsAuthenticated(state): boolean {
      return state.user != null;
    },
  },
});

export type AuthStore = ReturnType<typeof useAuthStore>;
