
import { defineComponent, ref } from 'vue';
import Modal from '../../../components/general/molecules/Modal.vue'
import TextField from '../../../components/general/atoms/TextField.vue'
import InputFile from '../../../components/general/atoms/InputFile.vue'
import Alert from '../../../components/general/atoms/Alert.vue';
import { BlockCommunity } from "@/services/communities/types";
import { CommunityService } from '@/services/communities/CommunityServices';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import ModalForm from './ModalForm.vue';
export default defineComponent({
  emit: ["update:visible", "update"],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    communityId: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {

    const communityService = new CommunityService();
    const block = ref<BlockCommunity>(new BlockCommunity());
    const showModal = ref(props.visible)
    const showAlert = ref<boolean>(false)
    const success = ref<boolean>(false)
    const itsCancel = ref<boolean>(false)
    const message = ref<string>("");
    const type = ref<string>("");
    const loading = ref<boolean>(false)
    const blockId = ref<number>(0)
    const showQuestionary = ref<boolean>(false)

    function validateForm() {
      if (!block.value.name || !block.value.totalFloors || !block.value.totalUnits || !block.value.address) {
        return false
      } else {
        return true
      }
    }

    const handleFile = (file: File) => {
      block.value.thumbnail = file
    }

    const openModalAlert = () => {
      loading.value = false
      showModal.value = false
    }
    const closeQuestionary = () => {
      showQuestionary.value = false
      emit('reload')
    }
    function cancelModal() {
      showModal.value = false
      itsCancel.value = true
      success.value = true
      emit('reload')
    }

    const saveBlock = async () => {
      block.value.communityId = props.communityId
      block.value.rut = "11111111-1"
      block.value.constructionYear = 1900
      loading.value = true
      const validate = validateForm()
      if (validate) {
        await communityService.createBlock(props.communityId, block.value)
          .then((response) => {
            if (response.code == 201) {
              communityService.addQuestionBlock(props.communityId, response.data.id ?? 0, 2)
              blockId.value = response.data.id ?? 0
              openModalAlert()
            }
          })
        showQuestionary.value = true

      } else {
        message.value = "Debe rellenar todos los campos";
        type.value = "warning";
        showAlert.value = true;
        loading.value = false

      }
      setTimeout(() => {
        showAlert.value = false;
      }, 4000);
    }

    const handleAcceptSuccess = () => {
      success.value = false;
      emit('accept');
    };

    const handleCancelSuccess = () => {
      success.value = false;
      emit('accept');
    };

    return { showModal, showQuestionary, showAlert, type, message, block, loading, blockId, cancelModal, itsCancel, saveBlock, closeQuestionary, handleFile, success, handleAcceptSuccess, handleCancelSuccess };
  },

  components: { Modal, TextField, InputFile, Alert, ModalAlert, ModalForm },
});
