
import { defineComponent, ref, toRef } from 'vue';
import Modal from '../../../general/molecules/Modal.vue'
import TextField from '../../../general/atoms/TextField.vue'
import Alert from '../../../general/atoms/Alert.vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';

export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        action: {
            type: String,
            default: '',
        },
        itemId: {
            type: Number,
            default: 0,
        }
    },
    setup(props, { emit }) {

        let tableData = ref([
            {
                id: 1,
                nombre: 'Ana Rodríguez',
                profesion: 'Ingeniera',
                depto: 'd',
                numero: '202',
                estac: '5',
                bodega: true,
                movil: '987654321'
            },
            {
                id: 2,
                nombre: 'Juan Pérez',
                profesion: 'Médico',
                depto: 'j',
                numero: '101',
                estac: '3',
                bodega: true,
                movil: '999888777'
            },
            {
                id: 3,
                nombre: 'María Gómez',
                profesion: 'Abogada',
                depto: 'L',
                numero: '401',
                estac: '8',
                bodega: true,
                movil: '111222333'
            }])
        const nombre = ref('');
        const profesion = ref('');
        const departamento = ref('');
        const numero = ref('');
        const estacionamiento = ref('');
        const bodega = ref(false);
        const movil = ref('');
        const showModal = ref(props.visible);
        const showAlert = ref(false)
        const message = ref("");
        const type = ref("");
        const loading = ref(false);
        const idItem = ref(props.itemId)
        const showModalAlert = ref(false)

        valueUpdate();

        function valueUpdate() {
            if (props.action == 'update') {
                const getUser = tableData.value.filter((item) => item.id === idItem.value)
                nombre.value = getUser[0].nombre
                profesion.value = getUser[0].profesion
                departamento.value = getUser[0].depto
                numero.value = getUser[0].numero
                estacionamiento.value = getUser[0].estac
                bodega.value = getUser[0].bodega
                movil.value = getUser[0].movil
            }
        }

        function cancelModal() {
            emit('update:visible', false);
            emit('update');
        }

        function validateForm() {
            if (!nombre.value || !profesion.value || !departamento.value || !numero.value || !estacionamiento.value || !bodega.value || !movil.value) {
                return false
            } else {
                return true
            }
        }

        function addOpenAlert() {
            showModal.value = false
            showModalAlert.value = true
        }

        function saveResident() {
            loading.value = true
            const validate = validateForm()
            if (validate) {
                if (props.action == 'create') {
                    message.value = "Datos Guardados con Exito";
                    type.value = "success";
                    console.log('Guardando bloque:', { nombre: nombre.value, profesion: profesion.value, departamento: departamento.value, numero: numero.value, estacionamiento: estacionamiento.value, bodega: bodega.value, movil: movil.value });
                    addOpenAlert()
                } else if (props.action == 'update') {
                    message.value = "Datos Editados con Exito";
                    type.value = "success";
                    console.log('Editando bloque:', { nombre: nombre.value, profesion: profesion.value, departamento: departamento.value, numero: numero.value, estacionamiento: estacionamiento.value, bodega: bodega.value, movil: movil.value });
                    addOpenAlert()
                }


            } else {
                message.value = "Debe rellenar todos los campos";
                type.value = "warning";
                showAlert.value = true;
                loading.value = false
            }
            setTimeout(() => {
                showAlert.value = false;
            }, 4000);
        }

        return {
            nombre,
            profesion,
            departamento,
            numero,
            estacionamiento,
            bodega,
            movil,
            loading,
            showModalAlert,
            showModal, showAlert, type, message, cancelModal, saveResident,

        };
    },

    components: { Modal, TextField, Alert,  ModalAlert},
});
