import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full" }
const _hoisted_2 = { class: "flex h-full justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddButton = _resolveComponent("AddButton")!
  const _component_TableComponent = _resolveComponent("TableComponent")!
  const _component_ModalResident = _resolveComponent("ModalResident")!
  const _component_ModalAlert = _resolveComponent("ModalAlert")!
  const _component_residentsModalDetail = _resolveComponent("residentsModalDetail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TableComponent, {
        title: 'Residentes',
        items: _ctx.formattedData,
        columns: _ctx.columns,
        buttons: _ctx.buttons,
        showButtons: _ctx.showButtons,
        searchTableData: _ctx.searchTableData
      }, {
        "extra-header": _withCtx(() => [
          _createVNode(_component_AddButton, {
            onClick: _ctx.addItem,
            class: "self-end w-full flex justify-end mr-4"
          }, null, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["items", "columns", "buttons", "showButtons", "searchTableData"])
    ]),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_ModalResident, _mergeProps({
          key: 0,
          visible: _ctx.showModal,
          action: _ctx.action
        }, { user: _ctx.action === 'update' ? _ctx.currentItem : undefined }, {
          onCloseModal: _ctx.cancel,
          onCreate: _ctx.create
        }), null, 16, ["visible", "action", "onCloseModal", "onCreate"]))
      : _createCommentVNode("", true),
    (_ctx.showModalAlert)
      ? (_openBlock(), _createBlock(_component_ModalAlert, {
          key: 1,
          title: _ctx.title,
          message: _ctx.message,
          visible: _ctx.showModalAlert,
          option: false,
          onAccept: _ctx.handleAccept,
          onDeleteItem: _ctx.handleDelete,
          onCancelAlert: _ctx.handleCancel
        }, null, 8, ["title", "message", "visible", "onAccept", "onDeleteItem", "onCancelAlert"]))
      : _createCommentVNode("", true),
    (_ctx.showResidentDetail && _ctx.selectFormattedData)
      ? (_openBlock(), _createBlock(_component_residentsModalDetail, {
          key: 2,
          item: _ctx.selectFormattedData,
          visible: _ctx.showResidentDetail,
          onCloseModal: _ctx.handleCloseModal
        }, null, 8, ["item", "visible", "onCloseModal"]))
      : _createCommentVNode("", true)
  ]))
}