
import { ref } from 'vue';

export default {
  props: {
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'success',
    }
  },
  setup() {
    const show = ref(true);

    setTimeout(() => {
      show.value = false;
    }, 3000);

    return {
      show,
    };
  }
}
