import { Response } from "../types";
import { AuthenticatedService } from "../auth/AuthenticatedServices";
import { getResponse, keysToSnake } from "../utils";
import { getEnvConfig } from "@/configs/UrlConfig";
import { BaseRegion, BaseProvinceRegion, BaseCountyProvince } from "./types";

export class AdressService extends AuthenticatedService {
    async getAllRegions(): Promise<Response<BaseRegion[]>> {
        const url = `${getEnvConfig().apiURL}/auth-extras/regions`;

        return getResponse(
            url,
            this.token,
            "GET",
            undefined,
            undefined,
            BaseRegion
        );
    }

    async getProvinceRegion(regionId: number): Promise<Response<BaseProvinceRegion[]>> {

        const url = `${getEnvConfig().apiURL}/auth-extras/regions/${regionId}/provinces`;

        return getResponse(
            url,
            this.token,
            "GET",
            undefined,
            undefined,
            BaseProvinceRegion
        );
    }

    async getCoutyProvince(provinceId: number): Promise<Response<BaseCountyProvince[]>> {

        const url = `${getEnvConfig().apiURL}/auth-extras/provinces/${provinceId}/counties`;

        return getResponse(
            url,
            this.token,
            "GET",
            undefined,
            undefined,
            BaseCountyProvince
        );
    }
}
