
import { defineComponent, ref } from 'vue';
import ManagementTab from '../atoms/ManagementTab.vue';
import InstructionResident from "@/instructionResident.json";
import InstructionPersonal from "@/instructionsPersonal.json";
import InquiriesHandleCard from '@/components/general/molecules/InquiriesHandleCard.vue';
import FormsHandlerCard from '@/components/general/molecules/FormsHandlerCard.vue';

export default defineComponent({
    setup() {
        const ShowRegistrationForm = ref(true);
        const showBuildings = ref(false);
        const showNotifications = ref(false);
        const showManage = ref(false);

        const init= () =>{
            // llamar al servicio de instrucciones
        }

        init()
        function turnOffAll() {
            ShowRegistrationForm.value = false;
            showBuildings.value = false;
            showNotifications.value = false;
            showManage.value = false;
        }

        return {
            turnOffAll,
            ShowRegistrationForm,
            showBuildings,
            showNotifications,
            showManage,
            InstructionResident,
            InstructionPersonal,
        };
    },
    components: { ManagementTab, InquiriesHandleCard, FormsHandlerCard }
})
