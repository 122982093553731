
import { defineComponent, ref } from "vue";
import ComponentCard from "@/components/general/molecules/ComponentCard.vue";
import ModalBlock from "./ModalBlock.vue";

export default defineComponent({
  props: {
    communityId: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const showModal = ref(false);

    function reload() {
      showModal.value = false;
      emit("reload");
    }
    return { showModal, reload };
  },
  components: { ComponentCard, ModalBlock },
});
