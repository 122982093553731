
import { defineComponent, ref, watch } from 'vue';
import ManagementTab from '../atoms/ManagementTab.vue';
import BlockManagement from './CommunityManagement.vue';
import CommunityForm from './CommunityForm.vue';
import BlocksTemplate from './BlocksTemplate.vue';
import PermissionRole from './PermissionRole.vue'
import QueriesCommunity from './QueriesCommunity.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
    props: {
        communityId: {
            type: Number,
            default: 0,
        },
    },

    setup(props) {

        const localCommunityId = ref(props.communityId);
        const router = useRouter();
        const pathName = ref(router.currentRoute.value.name)
        const showGeneral = ref(pathName.value == 'community-general');
        const showBuildings = ref(pathName.value == 'community-block');
        const showUserRole = ref(pathName.value == 'community-permission-role');
        const showManage = ref(pathName.value == 'community-management');
        const showQuery = ref(pathName.value == 'community-queries');
        watch(() => props.communityId, (newValue) => {
            localCommunityId.value = newValue;
        });

        function turnOffAll(routeName: any) {
            showGeneral.value = false;
            showBuildings.value = false;
            showManage.value = false;
            showUserRole.value = false;
            showQuery.value = false;
            goToRoute(routeName)
        }


        function goToRoute(routeName: any) {
            pathName.value = routeName
            router.push({
                name: routeName,
                params: {
                    id: localCommunityId.value,
                },
            });
        }
        return {
            turnOffAll,
            showGeneral,
            showBuildings,
            showQuery,
            showManage,
            showUserRole,
            PermissionRole,
            localCommunityId,
            goToRoute,
            pathName
        };
    },
    components: {
        ManagementTab, CommunityForm, BlocksTemplate,
        PermissionRole,
        BlockManagement,
        QueriesCommunity,
    }
})
