
import { defineComponent, ref, toRef } from 'vue';
import Modal from '../../../general/molecules/Modal.vue'
import TextField from '../../../general/atoms/TextField.vue'
import Alert from '../../../general/atoms/Alert.vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';

export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        action: {
            type: String,
            default: '',
        },
        itemId: {
            type: Number,
            default: 0,
        }
    },
    setup(props, { emit }) {
        let tableData = ref([
            {
                id: 1,
                kg: 5,
                descripcion: "esta es una descripcion del gas",
                FechaCompra: "2023-03-13",
            },
            {
                id: 2,
                kg: 15,
                descripcion: "esta es una descripcion del gas",
                FechaCompra: "2023-04-03",
            },
            {
                id: 3,
                kg: 15,
                descripcion: "esta es una descripcion del gas",
                FechaCompra: "2023-02-25",
            }])
        const kiloGramos = ref();
        const descripcion = ref('');
        const fechaCompra = ref();
        const showModal = ref(props.visible);
        const showAlert = ref(false)
        const message = ref("");
        const type = ref("");
        const loading = ref(false);
        const showModalAlert = ref(false)
        const idItem = ref(props.itemId)

        valueUpdate();

        function valueUpdate() {
            if (props.action == 'update') {
                const getUser = tableData.value.filter((item) => item.id === idItem.value)
                kiloGramos.value = getUser[0].kg
                descripcion.value = getUser[0].descripcion
                fechaCompra.value = getUser[0].FechaCompra
            }
        }

        function cancelModal() {
            emit('update:visible', false);
            emit('update');
        }

        function validateForm() {
            if (!kiloGramos.value || !descripcion.value || !fechaCompra.value) {
                return false
            } else {
                return true
            }
        }
        function addOpenAlert() {
            showModal.value = false
            showModalAlert.value = true
        }


        function saveGas() {
            loading.value = true
            const validate = validateForm()
            if (validate) {
                if (props.action == 'create') {
                    message.value = "Datos Guardados con Exito";
                    type.value = "success";
                    console.log('Guardando bloque:', { kiloGramos: kiloGramos.value, descripcion: descripcion.value, fechaCompra: fechaCompra.value });
                    addOpenAlert()
                } else if (props.action == 'update') {
                    message.value = "Datos Editados con Exito";
                    type.value = "success";
                    console.log('Editando bloque:', { kiloGramos: kiloGramos.value, descripcion: descripcion.value, fechaCompra: fechaCompra.value });
                    addOpenAlert()
                }


            } else {
                message.value = "Debe rellenar todos los campos";
                type.value = "warning";
                showAlert.value = true;
                loading.value = false
            }
            setTimeout(() => {
                showAlert.value = false;
            }, 4000);
        }

        return {
            kiloGramos,
            descripcion,
            fechaCompra,
            loading,
            showModalAlert,
            showModal, showAlert, type, message, cancelModal, saveGas,

        };
    },

    components: { Modal, TextField, Alert, ModalAlert },
});
