
import { defineComponent, ref } from 'vue';
import TextField from '../atoms/TextField.vue';

export default defineComponent({
    props: {
        width: {
            type: String,
            default: 'w-1/3'
        }
    },
    setup(_, { emit }) {
        const handleSearch = (value: string) => {
            emit('searchCommunity', value);
        };

        return {
            handleSearch
        };
    },

    components: { TextField }
});
