import { getResponse } from "../utils";
import { getEnvConfig } from "@/configs/UrlConfig";
import { Query, QueryGeneral, QueryWithOutIdGeneral } from "./types";
import { AuthenticatedService } from "../auth/AuthenticatedServices";
import { QueryWithOutId } from "./types";

export class QueriesServices extends AuthenticatedService {
    async createQuery(query: QueryWithOutIdGeneral) {
        const url = `${getEnvConfig().apiURL}/queries/create/`;

        return getResponse(url, this.token, 'POST', query, undefined, QueryGeneral)
    }
    async getQueries() {
        const url = `${getEnvConfig().apiURL}/queries/all`;

        return getResponse(url, this.token, 'GET', undefined, undefined, QueryGeneral)
    }

    async getQueryById(QueryId: number) {
        const url = `${getEnvConfig().apiURL}/queries/query/${QueryId}`;

        return getResponse(url, this.token, 'GET', undefined, undefined, QueryGeneral)
    }


    async updateQuery(communityId: number, query: QueryWithOutId, queryId: number) {
        const url = `${getEnvConfig().apiURL}/queries/community/${communityId}/query/${queryId}/edit`;

        return getResponse(url, this.token, "POST", query, undefined, Query);
    }


    async deleteQuery(communityId: number, QueryId: number) {
        const url = `${getEnvConfig().apiURL}/queries/community/${communityId}/query/${QueryId}/delete`;
    
        return getResponse(url, this.token, 'DELETE', undefined, undefined, Query)
    
      }
}