import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hover:scale-105" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(["border-gray-300 border-2 p-1 shadow-sm hover:bg-slate-100 flex", { 'rounded-t-lg': _ctx.roundTop, 'rounded-b-lg': _ctx.roundDown, 'rounded-l-lg': _ctx.roundLeft, 'rounded-r-lg': _ctx.roundRight }]),
      title: _ctx.titleText
    }, [
      _createVNode(_component_mdicon, { name: _ctx.iconName }, null, 8, ["name"]),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.arrayCount), 1)
    ], 10, _hoisted_2)
  ]))
}