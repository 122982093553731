
import ComponentCard from '@/components/general/molecules/ComponentCard.vue';
import { defineComponent, ref } from 'vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import { CommunityService } from "@/services/communities/CommunityServices";
import { getEnvConfig } from "@/configs/UrlConfig";
import CommunityButton from '../atoms/CommunityButton.vue';
import { CommunitySummary } from "@/services/communities/types";

export default defineComponent({
    props: {
        blocksCommunity: {
            type: [Array, CommunitySummary],
            require: true
        },
    },
    setup(props, { emit }) {
        const communityServices = new CommunityService();
        const isHovered = ref<boolean>(false)
        const blockId = ref<number>(0)
        const communityId = ref<number>(0)
        const title = ref<string>("")
        const message = ref<string>("")
        const success = ref<boolean>(false)
        const block = ref(props.blocksCommunity)

        const deleteBlock = async (id: any, commuId: any) => {
            blockId.value = id
            communityId.value = commuId
            title.value = "¿Desea Eliminar?"
            message.value = "¿Está seguro que desea eliminar el block?"
            success.value = true

        }

        const handleAcceptSuccess = async () => {
            success.value = false;
            await communityServices.deleteBlockCommunityService(communityId.value, blockId.value)
            emit('deleteCommunity')
        };

        const showBlock = () => {
            emit('showDetails')
        }

        const handleCancelSuccess = () => {
            success.value = false;
        };

        return {
            isHovered,
            title,
            message,
            success,
            deleteBlock,
            block,
            handleCancelSuccess,
            handleAcceptSuccess,
            showBlock,
            getEnvConfig
        };
    },
    components: { ComponentCard, ModalAlert, CommunityButton }
})
