
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        title: {
            type: String,
            required: false
        },
        bg_color: {
            type: String,
            default: 'white',
            require: false
        },
        color: {
            type: String,
            default: 'black',
            require: false
        }
    },

    setup() {
        return {
        }
    },
})
