
import { defineComponent, ref } from 'vue';
import Modal from '../../../general/molecules/Modal.vue'
import TextField from '../../../general/atoms/TextField.vue'
import Alert from '../../../general/atoms/Alert.vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';

export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        action: {
            type: String,
            default: '',
        },
        itemId: {
            type: Number,
            default: 0,
        }
    },
    setup(props, { emit }) {
        let tableData = ref([
            {
                id: 1,
                departamento: 'A',
                nombre_dueno: 'Juan Pérez',
                tipo_animal: 'Perro',
                cantidad: 2,
                movil: '555-1234'
            },
            {
                id: 2,
                departamento: 'B',
                nombre_dueno: 'Ana González',
                tipo_animal: 'Gato',
                cantidad: 1,
                movil: '555-5678'
            },
            {
                id: 3,
                departamento: 'C',
                nombre_dueno: 'Pedro Ramírez',
                tipo_animal: 'Perro',
                cantidad: 3,
                movil: '555-2468'
            }])
        const departamento = ref('');
        const dueno = ref('');
        const tipo = ref('');
        const cantidad = ref(0);
        const movil = ref('');
        const showModal = ref(props.visible);
        const showAlert = ref(false)
        const message = ref("");
        const type = ref("");
        const loading = ref(false);
        const showModalAlert = ref(false)
        const idItem = ref(props.itemId)


        valueUpdate();

        function valueUpdate() {
            if (props.action == 'update') {
                const getUser = tableData.value.filter((item) => item.id === idItem.value)
                departamento.value = getUser[0].departamento
                dueno.value = getUser[0].nombre_dueno
                tipo.value = getUser[0].tipo_animal
                cantidad.value = getUser[0].cantidad
                movil.value = getUser[0].movil

            }
        }

        function cancelModal() {
            emit('update:visible', false);
            emit('update');
        }

        function validateForm() {
            if (!departamento.value || !dueno.value || !tipo.value || !cantidad.value || !movil.value) {
                return false
            } else {
                return true
            }
        }

        function addOpenAlert() {
            showModal.value = false
            showModalAlert.value = true
        }

        function savePet() {
            loading.value = true
            const validate = validateForm()
            if (validate) {
                if (props.action == 'create') {
                    message.value = "Datos Guardados con Exito";
                    type.value = "success";
                    console.log('Guardando bloque:', { departamento: departamento.value, dueno: dueno.value, tipo: tipo.value, cantidad: cantidad.value, movil: movil.value });
                    addOpenAlert()
                } else if (props.action == 'update') {
                    message.value = "Datos Editados con Exito";
                    type.value = "success";
                    console.log('Guardando bloque:', { departamento: departamento.value, dueno: dueno.value, tipo: tipo.value, cantidad: cantidad.value, movil: movil.value });
                    addOpenAlert()
                }


            } else {
                message.value = "Debe rellenar todos los campos";
                type.value = "warning";
                showAlert.value = true;
                loading.value = false
            }
            setTimeout(() => {
                showAlert.value = false;
            }, 4000);
        }
        return {
            departamento,
            dueno,
            tipo,
            cantidad,
            movil,
            loading,
            showModalAlert,
            showModal, showAlert, type, message, cancelModal, savePet,

        };
    },

    components: { Modal, TextField, Alert, ModalAlert }
});
