
import { defineComponent, ref, computed, watch } from 'vue';
import Modal from '@/components/general/molecules/Modal.vue'
import TextField from '@/components/general/atoms/TextField.vue';
import AddButton from '@/components/general/atoms/AddButton.vue';
import Accordion from '@/components/general/atoms/Accordion.vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import { Question } from '@/services/communities/types';
export default defineComponent({
    emit: ["update:visible", "update", "closeModal", "create"],
    props: {
        questions: {
            type: Array,
            required: true
        },
        question:{
            type: Question,
            
        },
        blockId: {
            type: Number,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        data: {
            type: Object,
            required: true
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        interface FormInfo {
            title: string;
            content: string;
        }
        function initializeFormItems(dataAnswer: any, isResident: boolean): FormInfo[] {
            const formItems: FormInfo[] = [];
            const answersArray = isResident
                ? dataAnswer.answersDetailResident
                : dataAnswer.answersDetailPersonnel;
            const questionsArray = isResident
                ? dataAnswer.answersValueResident
                : dataAnswer.answersValuePersonnel;
            const numQuestions = answersArray[props.index].length;
            for (let i = 0; i < numQuestions; i++) {
                formItems.push({
                    title: questionsArray[props.index][i],
                    content: answersArray[props.index][i],
                });
            }
            return formItems;
        }
        const showModalAdd = ref<boolean>(props.visible)
        const isAdd = ref<boolean>(false)
        const title = ref<string>('')
        const description = ref<string>('')
        const formPersonalItems = ref<FormInfo[]>(initializeFormItems(props.data, false));
        const formResidentItems = ref<FormInfo[]>(initializeFormItems(props.data, true));
        const isFirstButton = computed(() => activeButton.value === 'resident');
        const isLastButton = computed(() => activeButton.value === 'personal');
        const activeButton = ref<'resident' | 'personal'>('resident');
        const isEditing = ref<boolean>(false)
        const editedTitle = ref<string>('')
        const editedDescription = ref<string>('')
        const showModalAlert = ref<boolean>(false)
        const currentItem = ref<number>(0)
        const isFieldsFilled = computed(() => {
            return title.value.trim() !== '' && description.value.trim() !== '';
        })
        const isFieldsFilledEdit = computed(() => {
            return editedTitle.value.trim() !== '' && editedDescription.value.trim() !== '';
        })
        const filteredItems = computed(() => {
            return activeButton.value === 'personal' ? formPersonalItems.value : formResidentItems.value;
        });
        const toggleButton = (type: 'resident' | 'personal') => {
            activeButton.value = type;
        };
        const closeModal = () => {
            showModalAdd.value = false
            emit('closeModal')
        }
        function addItem() {
            const item = {
                title: title.value,
                content: description.value
            }
            if (activeButton.value === 'resident') {
                formResidentItems.value.push(item)
            }
            else {
                formPersonalItems.value.push(item)
            }
            title.value = ''
            description.value = ''
            isAdd.value = false
        }
        function deleteAlert(index: number) {
            showModalAlert.value = true
            currentItem.value = index
        }
        function handleDelete() {
            if (activeButton.value === 'resident') {
                formResidentItems.value.splice(currentItem.value, 1)
            }
            else {
                formPersonalItems.value.splice(currentItem.value, 1)
            }
            showModalAlert.value = false
        }
        function edit(index: number) {
            isEditing.value = true
            editedTitle.value = formResidentItems.value[index].title
            editedDescription.value = formResidentItems.value[index].content
        }
        function handleEdit(index: number) {
            const item = {
                title: editedTitle.value,
                content: editedDescription.value
            }
            if (activeButton.value === 'resident') {
                formResidentItems.value.splice(index, 1, item)
            }
            else {
                formPersonalItems.value.splice(index, 1, item)
            }
            isEditing.value = false
        }
        function saveAddItem() {
            const data = {
                answersDetailResident: formResidentItems.value,
                answersDetailPersonnel: formPersonalItems.value
            }
            emit('create', data, props.index)
        }


        return {
            closeModal,
            addItem,
            handleDelete,
            toggleButton,
            handleEdit,
            edit,
            saveAddItem,
            deleteAlert,
            showModalAdd,
            title,
            description,
            filteredItems,
            isAdd,
            isFirstButton,
            isLastButton,
            activeButton,
            isEditing,
            editedTitle,
            editedDescription,
            showModalAlert,
            isFieldsFilled,
            isFieldsFilledEdit
        };
    },
    components: { Modal, TextField, AddButton, Accordion, ModalAlert },
});
