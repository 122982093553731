
import TextField from '@/components/general/atoms/TextField.vue';
import ComponentCard from '@/components/general/molecules/ComponentCard.vue';
import Modal from '@/components/general/molecules/Modal.vue';
import InputFile from '../../../components/general/atoms/InputFile.vue'
import { defineComponent, ref, computed, watch } from 'vue';
import Accordion from '@/components/general/atoms/Accordion.vue';
import { CreateCommunity } from "@/services/communities/types";
import { getEnvConfig } from "@/configs/UrlConfig";
import { CommunityService } from '@/services/communities/CommunityServices';
import { AccessControlWithId } from '@/services/communities/types';
import AddButton from '@/components/general/atoms/AddButton.vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import { validateRut } from "@/services/utils";
import Alert from "@/components/general/atoms/Alert.vue";

export default defineComponent({

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        communityId: {
            type: Number,
            default: 0,
        },
    },
    setup(props) {
        const communityService = new CommunityService()
        const success = ref<boolean>(false)
        const showModal = ref<boolean>(false)
        const isModified = ref(false)
        const communityUpdate = ref<CreateCommunity>(new CreateCommunity());
        const selectedItem = ref('');
        const messageAlert = ref<string>("")
        const typeAlert = ref<string>("")
        const showAlert = ref<boolean>(false)
        const loading = ref<boolean>(false)
        const formattedControlAccess = ref<{ title: string, content: string }[]>([]);
        const showModalEdit = ref<boolean>(false);
        const showModalAdd = ref<boolean>(false);
        const showAlertModal = ref<boolean>(false);
        const nameField = ref<string>("");
        const descriptionField = ref<string>("");
        const currentItem = ref<number>(0);
        const isFieldsFilled = computed(() => {
            return nameField.value.trim() !== '' && descriptionField.value.trim() !== '';
        });
        const showAceptAlert = ref<boolean>(false);
        const showEditAlert = ref<boolean>(false);
        const controlAccess = ref<AccessControlWithId[]>([])
        function onUpdateModel(value: string) {
            selectedItem.value = value;
        }
        const buildingTypes = ['a', 'b', 'c']
        const buildingType = ref("")
        const items = ref([
            {
                title: "Sistema de detección de incendios principal",
                content: "Este sistema de incendios es proporcionado por una empresa"
            }
        ])


        valueUpdate();

        async function valueUpdate() {
            const response = await communityService.getCommunity(props.communityId)
            const communityDate = response.data
            if (communityDate) {
                communityUpdate.value = communityDate
            }
        }


        const updateHandleCommunity = async () => {
            const validarRut = validateRut(communityUpdate.value.rut ?? "")
            if (validarRut) {
                await communityService.updateCommunity(props.communityId, communityUpdate.value)
                    .then((response) => {
                        if (response.code == 200) {
                            success.value = true
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    })
            } else {
                messageAlert.value = "Rut ingresado no cumple con el formato";
                typeAlert.value = "error";
                showAlert.value = true;
            }
            setTimeout(() => {
                showAlert.value = false;
            }, 4000);

        }

        const updateAccordion = (newValue = []) => {
            items.value = newValue;
        }
        async function getAccessControl() {
            try {
                const response = await communityService.getAccessControlByCommunity(props.communityId);
                if (!response.success) {
                    controlAccess.value = [];
                }
                else {
                    controlAccess.value = response.data
                }
            }
            catch (error) {
                console.log(error);
            }
        }
        getAccessControl()

        function updateThumbnail(file: File) {
            communityUpdate.value.thumbnail = file
            updateHandleCommunity()
            showModal.value = false
        }
        watch(controlAccess, () => {
            formattedControlAccess.value = controlAccess.value.map((data) => {
                return {
                    title: data.name,
                    content: data.description
                }
            })
        })
        async function addItem() {
            showModalAdd.value = true
            showAceptAlert.value = false
            descriptionField.value = ""
            nameField.value = ""
        }
        async function saveAddItem() {
            showModalAdd.value = false

            const BaseAccessControl = {
                name: nameField.value,
                description: descriptionField.value,
            }
            await communityService.createAccessControlByCommunity(props.communityId, BaseAccessControl).then((response) => {
                if (!response.success) {
                    return;
                }
                if (response.code === 201) {
                    getAccessControl();
                    showAceptAlert.value = true
                }
            })
        }

        async function editItem(index: number) {
            showModalEdit.value = true
            nameField.value = controlAccess.value[index].name
            descriptionField.value = controlAccess.value[index].description
            currentItem.value = index
            showEditAlert.value = false
        }

        async function saveEditItem() {
            loading.value = true
            showModalEdit.value = false
            controlAccess.value[currentItem.value].name = nameField.value
            controlAccess.value[currentItem.value].description = descriptionField.value
            const BaseAccessControl = {
                name: nameField.value,
                description: descriptionField.value,
            }
            await communityService.updateAccessControl(props.communityId, controlAccess.value[currentItem.value].id, BaseAccessControl).then((response) => {
                if (!response.success) {
                    return;
                }
                if (response.code === 200) {
                    getAccessControl();
                    loading.value = false
                    showEditAlert.value = true
                }
            })
            currentItem.value = 0
        }

        async function deleteItem(index: number) {
            currentItem.value = index
            showAlertModal.value = true
        }

        async function handleAccept() {
            try {
                showAlertModal.value = false;
                console.log("test", controlAccess.value[currentItem.value])
                await communityService.deleteAccessControl(props.communityId, controlAccess.value[currentItem.value].id)
                currentItem.value = 0;
            } catch (error) {
                console.error(error);
            }
            await getAccessControl();
        }

        const handleAcceptSuccess = () => {
            success.value = false;
            valueUpdate()
        };

        const handleInput = () => {
            isModified.value = true;
        };

        return {
            items,
            onUpdateModel,
            showModal,
            updateThumbnail,
            buildingTypes,
            buildingType,
            updateAccordion,
            isModified,
            handleInput,
            updateHandleCommunity,
            handleAcceptSuccess,
            communityUpdate,
            getEnvConfig,
            success,
            controlAccess,
            formattedControlAccess,
            nameField,
            descriptionField,
            showModalAdd,
            saveAddItem,
            showModalEdit,
            showAceptAlert,
            isFieldsFilled,
            showEditAlert,
            showAlertModal,
            addItem,
            deleteItem,
            handleAccept,
            saveEditItem,
            editItem,
            showAlert,
            messageAlert,
            typeAlert,
            loading
        };
    },

    components: { TextField, ComponentCard, Modal, InputFile, Alert, Accordion, AddButton, ModalAlert }
})
