
import { defineComponent, ref, computed } from 'vue';
import Modal from '@/components/general/molecules/Modal.vue'
import Selector from '@/components/general/atoms/Selector.vue'
import { UserService } from "@/services/users/UserServices";


export default defineComponent({
    emit: ["update:visible", "update", "closeModal", "create"],
    props: {
        user: {
            type: Object,
            required: true,
        },
        communityId: {
            type: Number,
            required: true
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        console.log(props.user)
        const permissions = ref([
            { value: 1, label: 'Residente' },
            { value: 2, label: 'Trabajador' },
            { value: 3, label: 'Administrador' }
        ])
        const userService = new UserService();
        const loading = ref<boolean>(false)
        const role = ref<number>(props.user?.userRole)
        const showModal = ref<boolean>(props.visible)
        const closeModal = () => {
            showModal.value = false
            emit('closeModal')
        }
        const onUpdateRol = (e: number) => {
            role.value = e
        }
        const editRol = async () => {
            loading.value = true
            const data = {
                userId: Number(props.user.userId),
                communityRole: Number(role.value)
            }
            await userService.changeRole(props.communityId, data)
            loading.value = false
            emit('change')
        }
        return {
            closeModal,
            onUpdateRol,
            editRol,
            showModal,
            permissions,
            role,
            loading
        };
    },
    components: { Modal, Selector },
});
