
import { defineComponent, ref, watch } from 'vue';
import ModalAlertResolved from './ModalAlertResolved.vue';

export default defineComponent({
    props: {
        title: { type: String, default: '' },
        message: { type: String, default: '' },
        visible: { type: Boolean, default: true },
        option: { type: Boolean},
        itsCancel: { type: Boolean, default:false},
    },
    setup(props, { emit }) {
        const type = ref(props.itsCancel? 'cancel': 'success');
        const titleSuccess = ref(props.itsCancel? 'Cancelado':'!Éxito!');
        const messageSuccess = ref(props.itsCancel?'La operación fue Cancelada.' : 'La operación fue realizada con éxito.');
        const isVisible = ref(props.visible);
        const success = ref(props.option);

        watch(() => props.visible, (newValue) => {
            isVisible.value = newValue;
        });

        const handleSuccess = () => {
            success.value = true;
            titleSuccess.value = 'Realizado'
            messageSuccess.value = 'Operación realizada con éxito'
            type.value = "success";
        };

        const handleCancel = () => {
            success.value = true;
            titleSuccess.value = 'Cancelado'
            messageSuccess.value = 'Operación Cancelada con éxito'
            type.value = "cancel";
        };

        const handleCloseSuccess = () => {
            success.value = false;
            isVisible.value = false;
            emit('cancelAlert')
        };

        const handleAcceptSuccess = () => {
            success.value = false;
            isVisible.value = false;
            emit('accept');
            emit('deleteItem')
        };
 
 
        return {
            success,
            handleSuccess,
            handleCloseSuccess,
            handleCancel,
            handleAcceptSuccess,
            type,
            titleSuccess,
            messageSuccess,
            isVisible,
        };
    },
    components: {
        ModalAlertResolved,
    },
});
