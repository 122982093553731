
import { ref } from 'vue'
import { useRouter } from 'vue-router';
import { AuthService } from '@/services/auth/AuthServices'
import { useAuthStore } from '../../stores/auth'
import { defineComponent, onMounted } from 'vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import { NotificationBase } from "@/services/notifications/types";
import { NotificationServices } from "@/services/notifications/NotificationServices";
import { CommunityService } from '@/services/communities/CommunityServices';
export default defineComponent({
    setup(porps, { emit }) {
        const isOpen = ref(true)
        const loading = ref(false)
        const showModalAlert = ref(false)
        const notificationServices = new NotificationServices();
        const authService = new AuthService();
        const showNotifications = ref<boolean>(false)
        const checkNotification = ref<number[]>([]);
        const router = useRouter();
        const hasError = ref(false)
        const authStore = useAuthStore();
        const authSuperUser = authStore.user;
        const notifications = ref<NotificationBase[]>([]);
        const communityService = new CommunityService();

        onMounted(async () => {
            init()
        });
        const init = () => {
            checkNotification.value = []
            obtainNotification()
        }


        function addOpenAlert() {
            showModalAlert.value = true
        }
        const obtainNotification = async () => {
            const authToken = authStore.userSession;
            if (authToken) {
                await notificationServices.getNotificationUser(authToken?.userId)
                    .then((response: { data: any[]; }) => {
                        notifications.value = response.data.filter(item => item.isActive === true);
                    })
                await communityService.checkDate(authToken?.userId)
            }
        };

        const handleCancel = () => {
            showModalAlert.value = false;

        };
        const handleAccept = () => {
            showModalAlert.value = false
            logout()
        };

        async function logout() {
            loading.value = true;
            const authToken = authStore.token as string;
            try {
                const response = await authService.logout(authToken);
                if (response.success) {
                    authStore.logout();
                    router.push('/');
                } else {
                    hasError.value = true;
                }
            } catch (error) {
                console.error(error);
                hasError.value = true;
            } finally {
                loading.value = false;
            }
        }

        const saveNotification = async (id: number) => {
            const index = checkNotification.value.indexOf(id);

            if (index !== -1) {
                checkNotification.value.splice(index, 1);
            } else {
                checkNotification.value.push(id);
            }
        };
        const sendCheckNotification = async () => {
            const authToken = authStore.userSession;
            if (authToken) {
                const receivers = {
                    receiver_ids: checkNotification.value,
                    is_active: false
                }
                await notificationServices.updateNotifications(authToken?.userId, receivers)
                    .then((response) => {
                        init()
                    })
            }
        }

        const hiddenNotification = async () => {
            showNotifications.value = !showNotifications.value
            checkNotification.value = []
        }

        return {
            isOpen,
            addOpenAlert,
            showModalAlert,
            handleAccept,
            handleCancel,
            authSuperUser,
            notifications,
            saveNotification,
            checkNotification,
            showNotifications,
            sendCheckNotification,
            hiddenNotification
        }
    },
    components: { ModalAlert },


})
