<template>
  <div class="selector w-full ">
    <label v-if="label" class="flex text-sm font-normal pb-2 font-semibold" for="selection"
      :class="{ 'text-red-500': hasError, 'text-gray-700': !hasError }">
      <span class="font-semibold">{{ label }}</span>
      <div class="text-red-500 ml-auto flex place-items-center" v-if="hasError">
        <Popper arrow class="font-normal" placement="auto" hover
          :content="errorMessage">
          <mdicon name="error" size="16" />
        </Popper>
      </div>
    </label>
    <div class="inline-block relative w-full">
      <div class="flex items-center relative select-none h-[38px]">
        <select v-model="selectedItems" :disabled="itsDisabled" @change="onChange"
          id="selection"
          class="appearance-none border rounded-xl w-full py-1 px-2 leading-tight 
                focus:outline-none cursor-pointer"
          :class="{
            [backGround]: backGround,
            'border-gray-300': !hasError,
            'text-red-700 border-red-300 focus:border-red-800 hover:border-red-400': hasError,
          }">
          <option value=0 disabled selected hidden>{{ placeHolder }} </option>
          <option :class="{ [backGround]: backGround, }" v-for="(item) in items"
            :key="item.value" :value="item.value">{{ item.label }} 
          </option>
        </select>
        <mdicon name="chevron-down" size="16" class="absolute right-2 pointer-events-none"/>
      </div>
    </div>
  </div>
</template>

<script >
import { ref, defineComponent } from 'vue';

export default defineComponent({
  name: 'Selector',
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    },
    selectedItem: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    itsDisabled: {
      type: Boolean,
      default: false
    },
    placeHolder: {
      type: String,
      default: 'Seleccione una Opción'
    },
    backGround: {
      type: String,
      default: ''
    },
    borderColor: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: ''
    },
    fountText: {
      type: String,
      default: ''
    },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const selectedItems = ref(props.selectedItem);

    function onChange(event) {
      selectedItems.value = event.target.value;
      emit('update', selectedItems.value);
    }

    return {
      selectedItems,
      onChange,
    };
  }
});
</script>

<style scoped lang="postcss">
.dropdown:hover .dropdown-menu {
  display: block;
}
</style>
