import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container mx-auto px-4 py-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableComponent = _resolveComponent("TableComponent")!
  const _component_ModalAlert = _resolveComponent("ModalAlert")!
  const _component_AlertBasic = _resolveComponent("AlertBasic")!
  const _component_PermissionModal = _resolveComponent("PermissionModal")!
  const _component_PermisionModalDetail = _resolveComponent("PermisionModalDetail")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("section", _hoisted_1, [
      _createVNode(_component_TableComponent, {
        title: 'Residentes',
        items: _ctx.formattedData,
        columns: _ctx.columns,
        buttons: _ctx.buttons,
        showButtons: _ctx.showButtons,
        searchTableData: _ctx.searchTableData
      }, null, 8, ["items", "columns", "buttons", "showButtons", "searchTableData"]),
      _createVNode(_component_ModalAlert, {
        title: _ctx.title,
        message: _ctx.message,
        visible: _ctx.showModal,
        option: false,
        onAccept: _ctx.handleAccept,
        onDeleteItem: _ctx.handleDelete,
        onCancelAlert: _ctx.handleCancel
      }, null, 8, ["title", "message", "visible", "onAccept", "onDeleteItem", "onCancelAlert"]),
      (_ctx.showAlert)
        ? (_openBlock(), _createBlock(_component_AlertBasic, {
            key: 0,
            type: _ctx.type,
            message: _ctx.message
          }, null, 8, ["type", "message"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showModalEdit && _ctx.currentItem)
      ? (_openBlock(), _createBlock(_component_PermissionModal, {
          key: 0,
          visible: _ctx.showModalEdit,
          user: _ctx.currentItem,
          communityId: _ctx.communityId,
          onCloseModal: _ctx.handleCloseModal,
          onChange: _ctx.change
        }, null, 8, ["visible", "user", "communityId", "onCloseModal", "onChange"]))
      : _createCommentVNode("", true),
    (_ctx.showDetails && _ctx.currentItem)
      ? (_openBlock(), _createBlock(_component_PermisionModalDetail, {
          key: 1,
          item: _ctx.currentItem,
          visible: _ctx.showDetails,
          onCloseModal: _ctx.handleCloseModal
        }, null, 8, ["item", "visible", "onCloseModal"]))
      : _createCommentVNode("", true)
  ]))
}