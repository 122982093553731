import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex w-full" }
const _hoisted_2 = { class: "h-full w-1/2" }
const _hoisted_3 = { class: "ml-2 h-full w-1/2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableComponent = _resolveComponent("TableComponent")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    title: `Formulario `,
    onOnClose: _ctx.closeModal,
    visible: _ctx.showModal,
    "onUpdate:visible": _cache[0] || (_cache[0] = (e) => _ctx.showModal = e)
  }, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.item.parkingSpotsAux.length > 0)
            ? (_openBlock(), _createBlock(_component_TableComponent, {
                key: 0,
                title: 'Estacionamientos',
                items: _ctx.item.parkingSpotsAux ?? [],
                columns: _ctx.columns,
                buttons: [],
                showButtons: _ctx.showButtons,
                isSelectPage: false
              }, null, 8, ["items", "columns", "showButtons"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.item.wareHouse.length > 0)
            ? (_openBlock(), _createBlock(_component_TableComponent, {
                key: 0,
                title: 'Almacenes',
                items: _ctx.item.wareHouse ?? [],
                columns: _ctx.columns,
                buttons: [],
                showButtons: _ctx.showButtons,
                isSelectPage: false
              }, null, 8, ["items", "columns", "showButtons"]))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.item.units.length > 0)
        ? (_openBlock(), _createBlock(_component_TableComponent, {
            key: 0,
            class: "mt-4",
            title: 'Unidades',
            items: _ctx.formattedData,
            columns: _ctx.columnsUnits,
            buttons: [],
            showButtons: _ctx.showButtons,
            isSelectPage: false
          }, null, 8, ["items", "columns", "showButtons"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onOnClose", "visible"]))
}