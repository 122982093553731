import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import mdiVue from "mdi-vue/v3"
import * as mdijs from "@mdi/js"
import Popper from "vue3-popper";

const pinia = createPinia()
const app = createApp(App)

app.directive('click-outside', {
    mounted(el, binding) {
      el.clickOutsideEvent = function(event: Event) {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event, el);
        }
      };
      document.body.addEventListener('mousedown', el.clickOutsideEvent);
    },
    unmounted(el) {
      document.body.removeEventListener('mousedown', el.clickOutsideEvent);
    }
});


app.use(pinia)
app.use(router)
app.use(mdiVue, {icons: mdijs})
app.component("Popper", Popper);
app.mount('#app')