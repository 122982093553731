
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

export default defineComponent({
    props: {
        title: {
            type: String,
            required: true
        },
        visible: {
            type: Boolean,
            default: false,
            required: false
        },
        nestedModalOpen: {
            type: Boolean,
            default: false,
        }
    },

    emits: ["update:visible", "onClose", "close"],

    setup(props, { emit }) {
        const openingModal = ref(false)

        function clickOutside() {
            if (!openingModal.value && !props.nestedModalOpen) {
                close()
            }
        }

        function close() {
            emit('close')
            emit('update:visible', false)
        }

        function onPressEsc(event: KeyboardEvent) {
            if (event.key == "Escape") {
                close()
            }
        }

        onMounted(() => {
            addEventListener("keyup", onPressEsc);
        })

        onUnmounted(() => {
            removeEventListener("keyup", onPressEsc)
        })

        return {
            openingModal, clickOutside, close
        }
    },

    watch: {
        visible(value: boolean) {
            if (!value) {
                this.$emit("onClose");
            }
            else {
                this.openingModal = true
                setTimeout(() => {
                    this.openingModal = false
                }, 500);
            }
        }
    }
});
