import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full inline-flex justify-between" }
const _hoisted_2 = { class: "text-sm px-3 overflow-x-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComponentCard = _resolveComponent("ComponentCard")!

  return (_openBlock(), _createBlock(_component_ComponentCard, { title: _ctx.title }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "extra-header", { class: "right-2" })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("table", {
          class: _normalizeClass(["w-full border-spacing-y-3 border-separate", {'table-fixed': _ctx.fixed, 'table-auto': !_ctx.fixed}
                ])
        }, [
          _renderSlot(_ctx.$slots, "head"),
          _renderSlot(_ctx.$slots, "body")
        ], 2)
      ])
    ]),
    _: 3
  }, 8, ["title"]))
}