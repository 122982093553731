
import AddButton from "@/components/general/atoms/AddButton.vue";
import TableComponent from "@/components/general/molecules/TableComponent.vue";
import { defineComponent, ref, computed } from "vue";
import ModalAlert from "@/components/general/molecules/ModalAlert.vue";
import TextField from "@/components/general/atoms/TextField.vue";
import { CommunityService } from "@/services/communities/CommunityServices";
import { FireExtinguisher } from "@/services/communities/types";
import Modal from "@/components/general/molecules/Modal.vue";
import Selector from "@/components/general/atoms/Selector.vue";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  setup() {
    const FireExtinguisherType = [
      { value: 1, label: "SOLIDO" },
      { value: 2, label: "LIQUIDO" },
      { value: 3, label: "ELECTRICO" },
      { value: 4, label: "METAL" },
      { value: 5, label: "GRASOS" },
    ];
    const route = useRoute();
    const router = useRouter();
    const communityId = ref<number>(
      typeof route.params.id === "string" ? parseInt(route.params.id) : 0
    );
    const blockId = ref<number>(0);
    const CommunityServices = new CommunityService();
    const quantity = ref<number>(0);
    const maintenance = ref<string>("");
    const showModalAlert = ref<boolean>(false);
    const showModal = ref<boolean>(false);
    const typeBuilding = ref<number>(0);
    const showModalAdd = ref<boolean>(false);
    const showAlert = ref<boolean>(false);
    const showModalEdit = ref<boolean>(false);
    const showEditAlert = ref<boolean>(false);
    const currentItem = ref<FireExtinguisher>({} as FireExtinguisher);
    const showAlertModal = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const blocks = ref();
    const selectBlock = ref();
    const isFieldsFilled = computed(() => {
      return !!quantity.value && !!maintenance.value && !!typeBuilding.value; //vencimiento.value && descripcion.value && typeBuilding.value;
    });
    const FireExtinguisherData = ref<FireExtinguisher[]>([]);
    const columns = ref([
      { name: "id", label: "ID" },
      { name: "extinguisherLabel", label: "Tipo de extintor" },
      { name: "maintenance", label: "Mantenimiento" },
      { name: "quantity", label: "Cantidad" },
    ]);
    const searchTableData = ref(["extinguisherLabel", "maintenance"]);
    async function getFireExtinguishers() {
      try {
        const response = await CommunityServices.getFireExtinguishers(communityId.value);
        if (!response.success) {
          FireExtinguisherData.value = [];
        } else {
          FireExtinguisherData.value = response.data;
          console.log(FireExtinguisherData.value);
        }
      } catch (error) {
        console.log(error);
      }
    }
    getFireExtinguishers();
    const formattedData = computed(() => {
      return FireExtinguisherData.value.map((item) => {
        let maintenance;
        if (item.maintenance) {
          let [dateString, timeString] = item.maintenance.split("T");
          let [year, month, day] = dateString.split("-").map(Number);
          let [hour, minute, second] = timeString.split(":").map(Number);
          let expirationDate = new Date(year, month - 1, day, hour, minute, second);
          console.log(expirationDate.toLocaleString());
          let isoDate =
            expirationDate.getFullYear() +
            "-" +
            (expirationDate.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            expirationDate.getDate().toString().padStart(2, "0") +
            "T" +
            expirationDate.getHours().toString().padStart(2, "0") +
            ":" +
            expirationDate.getMinutes().toString().padStart(2, "0") +
            ":" +
            expirationDate.getSeconds().toString().padStart(2, "0");
          maintenance = isoDate.slice(0, 10);
          console.log("hola", maintenance);
        }
        return {
          id: item.id,
          extinguisherType: item.extinguisherType,
          extinguisherLabel: FireExtinguisherType[item.extinguisherType - 1].label,
          quantity: item.quantity,
          maintenance: maintenance,
          blockId: item.blockId,
        };
      });
    });

    const getBlocksCommunity = () => {
      CommunityServices.getCommunityBlocks(communityId.value).then((response) => {
        blocks.value = response.data;
      });
    };
    getBlocksCommunity();
    const formattedBlockList = computed(() => {
      return blocks.value.map((item: any) => {
        return {
          value: item.id ? item.id : undefined,
          label: item.address ? item.address : undefined,
        };
      });
    });
    async function addItem() {
      quantity.value = 0;
      maintenance.value = "";
      typeBuilding.value = 0;
      showModalAdd.value = true;
      currentItem.value = {} as FireExtinguisher;
      typeBuilding.value = 0;
      blockId.value = 0;
    }
    async function saveEditItem() {
      loading.value = true;
      showModalEdit.value = false;
      const date = new Date(maintenance.value);
      const extinguisher = {
        quantity: quantity.value,
        maintenance: date.toISOString(),
        extinguisherType: Number(typeBuilding.value),
        blockId: Number(blockId.value),
      };

      await CommunityServices.updateFireExtinguisher(
        communityId.value,
        blockId.value,
        currentItem.value.id,
        extinguisher
      );
      currentItem.value = {} as FireExtinguisher;
      getFireExtinguishers();
      loading.value = false;
      showEditAlert.value = true;
    }
    const showButtons = computed(() => true);
    const updateTable = (index: FireExtinguisher) => {
      console.log(index);
      console.log(index.blockId);
      currentItem.value = index;
      blockId.value = index.blockId;
      showModalEdit.value = true;
      typeBuilding.value = index.extinguisherType;
      quantity.value = index.quantity;
      maintenance.value = index.maintenance.toString();
      showEditAlert.value = false;
    };

    const deleteTable = (index: FireExtinguisher) => {
      currentItem.value = index;
      showAlertModal.value = true;
      console.log(index, "eliminar");
    };
    function onUpdateModel(value: number) {
      console.log(value);
      typeBuilding.value = value;
    }
    const buttons = ref([
      {
        id: 1,
        name: "Editar",
        action: updateTable,
        className: "bg-warning text-white hover:bg-yellow-400 focus:bg-yellow-400",
        iconName: "pencil",
      },
      {
        id: 2,
        name: "Eliminar",
        action: deleteTable,
        className: "bg-red-600 text-white hover:bg-red-500 focus:bg-red-500",
        iconName: "delete",
      },
    ]);
    async function saveExtinguisher() {
      console.log(maintenance.value);
      const date = new Date(maintenance.value);

      const extinguisher = {
        quantity: quantity.value,
        maintenance: date.toISOString(),
        extinguisherType: Number(typeBuilding.value),
        blockId: blockId.value,
      };
      console.log(extinguisher);
      await CommunityServices.createFireExtinguisher(
        communityId.value,
        blockId.value,
        extinguisher
      );
      showModalAdd.value = false;
      getFireExtinguishers();
      showEditAlert.value = true;
    }

    async function handleAccept() {
      try {
        showAlertModal.value = false;
        await CommunityServices.deleteFireExtinguisher(
          communityId.value,
          blockId.value,
          currentItem.value.id
        );
        currentItem.value = {} as FireExtinguisher;
      } catch (error) {
        console.error(error);
      }
      await getFireExtinguishers();
    }

    const back = () => {
      router.push({
        path: `/community/${communityId.value}/block/${blockId.value}/queries/census`,
      });
    };
    const onSelect = (item: number) => {
      blockId.value = Number(item);
      console.log(blockId.value);
    };

    return {
      showModal,
      FireExtinguisherData,
      columns,
      showButtons,
      buttons,
      formattedData,
      searchTableData,
      showModalAlert,
      showModalAdd,
      FireExtinguisherType,
      showAlert,
      showAlertModal,
      showModalEdit,
      showEditAlert,
      isFieldsFilled,
      typeBuilding,
      quantity,
      maintenance,
      selectBlock,
      blocks,
      blockId,
      formattedBlockList,
      getFireExtinguishers,
      onUpdateModel,
      addItem,
      saveExtinguisher,
      handleAccept,
      saveEditItem,
      back,
      onSelect,
      loading,
    };
  },
  components: { TableComponent, Modal, ModalAlert, AddButton, Selector, TextField },
});
