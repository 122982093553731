import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative w-full" }
const _hoisted_2 = {
  key: 0,
  class: "absolute bg-white z-30 shadow rounded mt-2 w-full"
}
const _hoisted_3 = { class: "overflow-auto max-h-48" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen)),
      class: "bg-gray-200 w-full px-4 py-2 rounded flex items-center justify-between border border-gray-300"
    }, [
      _createTextVNode(_toDisplayString(_ctx.selected) + " ", 1),
      _createVNode(_component_mdicon, {
        class: _normalizeClass(["w-5 h-5 transform transition-transform duration-200 text-black", { 'rotate-180': _ctx.isOpen }]),
        name: "chevron-down",
        size: "24",
        color: "currentColor"
      }, null, 8, ["class"])
    ]),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
            class: "border border-gray-500 p-2 w-full rounded",
            placeholder: "Buscar..."
          }, null, 512), [
            [_vModelText, _ctx.search]
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item['id'],
                onClick: ($event: any) => (_ctx.selectItem(item)),
                class: "p-2 hover:bg-gray-300 cursor-pointer border border-gray-300"
              }, _toDisplayString(item[_ctx.searchKey]), 9, _hoisted_4))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}