
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        roundLeft: {
            type: Boolean,
            default: false
        },
        roundRight: {
            type: Boolean,
            default: false
        },
        content: {
            type: String,
            required: true
        },
        news: {
            type: Number,
            default: 0
        },
        selected: {
            type: Boolean,
            default: false
        }
    },

    setup() {
        const isSelected = ref(false);

        function select() {
            isSelected.value = !isSelected.value;
        }

        return {
            isSelected,
            select
        };
    }
});
