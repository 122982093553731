
import AddButton from '@/components/general/atoms/AddButton.vue';
import { defineComponent, ref, computed } from 'vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import { UserService } from '@/services/users/UserServices';
import TableComponent from '@/components/general/molecules/TableComponent.vue'
import { CommunityService } from '@/services/communities/CommunityServices';
import { Users, FormattedDataResident } from '@/services/communities/types';
import ModalResident from '@/components/communities/molecules/ModalResident.vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import residentsModalDetail from '@/components/communities/molecules/residentsModalDetail.vue';


export default defineComponent({
    props: {
        communityId: {
            type: Number,
            default: 0,
        },
    },
    setup(props) {
        interface formatted {
            id: number,
            firsName: string,
            lastName: string,
            email: string,
            rut: string,
            phone: string,
            userRol: string,
            userInCommunity: number,
            parkingSpace: string,
            warehouseSpace: string,
            floor: number,
            address: string,
            numBlock: number,
            establishments: string
        }
        const route = useRoute();
        const UserServices = new UserService()
        const communityId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const CommunityServices = new CommunityService();
        const showModal = ref<boolean>(false)
        const userInfo = ref<Users[]>([])
        const action = ref<string>('')
        const showModalEdit = ref<boolean>(false)
        const searchTableData = ref(['firsName', 'name']);
        const showButtons = computed(() => true)
        const numBlock = ref<string>('')
        const currentItem = ref<Users>()
        const showAlertModal = ref<boolean>(false)
        const message = ref<string>('')
        const showModalAlert = ref<boolean>(false)
        const title = ref<string>('')
        const iDinamic = ref<number>(0)
        const showResidentDetail = ref<boolean>(false)
        const selectFormattedData = ref<FormattedDataResident>()
        const establishments = [
            { id: 1, label: 'Oficina' },
            { id: 2, label: 'Residencia' },
            { id: 3, label: 'Local Comercial' },

        ];
        const columns = ref([{ name: 'address', label: 'Unidad' }, { name: 'firsName', label: 'Nombre' } , { name: 'phone', label: 'Telefono' }]);


        const formattedData = computed(() => {
            return userInfo.value.map((item) => {
                const data: FormattedDataResident = {
                    userId: item.userId,
                    position: item.position,
                    parkingSpots: item.parkingSpots.map(spot => spot.description).join(', '),
                    parkingSpotsAux: item.parkingSpots,
                    warehouseSpace: item.warehouses.map(spot => spot.description).join(', '),
                    wareHouse: item.warehouses,
                    userRole: item.userRole,
                    isActive: item.isActive,
                    isCommitteMember: item.isCommitteeMember,
                    committePosition: item.committeePosition,
                    isFab: item.isFab,
                    communityId: item.communityId,
                    id: item.id,
                    firsName: item.user.firstName + ' ' + item.user.lastName,
                    lastName: item.user.lastName,
                    profession: item.user.profession,
                    age: item.user.age,
                    phone: item.user.phone,
                    hasReducedCapacity: item.user.hasReducedCapacity,
                    reducedCapacityDetails: item.user.reducedCapacityDetails,
                    email: item.user.email,
                    rut: item.user.dni,
                    userInCommunity: item.id,
                    floor: item.communityUserUnits && item.communityUserUnits.length > 0 ? item.communityUserUnits[0].unit.floor : undefined,
                    address: item.communityUserUnits.map(spot => spot.unit.address).join(', '),
                    establishments: item.communityUserUnits && item.communityUserUnits.length > 0 ? establishments[item.communityUserUnits[0].unit.establishmentType - 1].label : undefined,
                    units: item.communityUserUnits
                }
                return data
            })
        })
        async function getCommunityResidents() {
            try {
                const response = await UserServices.getInactiveUsers(communityId.value);
                if (!response.success) {
                    userInfo.value = [];
                }
                else {
                    userInfo.value = response.data.filter((user: { isActive: boolean; }) => user.isActive === false);
                }
            }
            catch (error) {
                console.log(error);
            }
        }
        getCommunityResidents();
        function updateTable(item: any) {
            currentItem.value = userInfo.value.find((x) => x.userId === item.userId);
            showModal.value = true
            action.value = 'update'
        }

        const reload = () => {
            getCommunityResidents();
        }


        function onUpdateModelUnit(value: string) {
            numBlock.value = value;
            console.log(value)
        }

        const deleteTable = async (registro: any) => {
            iDinamic.value = registro.id
            title.value = '¿Desea Eliminar?'
            message.value = 'No sera posible retroceder la Acción'
            showModalAlert.value = true
        }
        const datailsTable = async (registro: any) => {
            showResidentDetail.value = true
            selectFormattedData.value = registro
            console.log(showResidentDetail.value)
        }

        const buttons = ref([
            { id: 1, name: 'Editar', action: updateTable, className: ' flex items-center justify-center bg-warning text-white hover:bg-yellow-400 focus:bg-yellow-400', iconName: 'pencil' },
            { id: 2, name: 'Detalles', action: datailsTable, className: 'bg-success text-white hover:bg-green-400 focus:bg-green-400', iconName: 'eye-outline' },

        ])
        function cancel() {
            showModal.value = false
        }

        const handleCancel = () => {
            showModalAlert.value = false;
        }

        const handleDelete = async () => {
            await CommunityServices.deletePersonalService(communityId.value, iDinamic.value)
            showModalAlert.value = false;
            getCommunityResidents()
        }

        function addItem() {
            showModal.value = true
            action.value = 'create'
            console.log(showModal.value)
        }
        const handleAccept = () => {
            showModalAlert.value = false;
            showModal.value = false
        }
        function create() {
            showModal.value = false
            getCommunityResidents()
        }
        const handleCloseModal = () => {
            showResidentDetail.value = false
        }
        return {
            create,
            handleCloseModal,
            reload,
            handleDelete,
            handleCancel,
            handleAccept,
            addItem,
            cancel,
            onUpdateModelUnit,
            formattedData,
            columns,
            buttons,
            searchTableData,
            showButtons,
            numBlock,
            establishments,
            showModalEdit,
            showAlertModal,
            updateTable,
            datailsTable,
            currentItem,
            action,
            showModal,
            message,
            userInfo,
            title,
            showModalAlert,
            showResidentDetail,
            selectFormattedData
        };
    },
    components: { AddButton, TableComponent, ModalAlert, ModalResident, residentsModalDetail },
});
