
import { defineComponent, ref, computed } from 'vue'

export default defineComponent({
    props: {
        items: {
            type: Array as () => Record<string, any>[],
            required: true
        },
        searchKey: {
            type: String,
            required: true
        },
        placeHolder: {
            type: String,
            default: 'Selecciona un item',
            required: false
        }
    },
    emits: ['select'],
    setup(props, { emit }) {
        const selected = ref<string>(props.placeHolder)
        const isOpen = ref<boolean>(false)
        const search = ref<string>('')
        const filteredItems = computed(() => {
            return props.items.filter(item => item[props.searchKey].toLowerCase().includes(search.value.toLowerCase()))
        })

        const selectItem = (item: Record<string, any>) => {
            search.value = ''
            selected.value = item[props.searchKey]
            isOpen.value = false
            console.log(item.vl)
            emit('select', item)
        }

        return { selected, isOpen, search, filteredItems, selectItem }
    }
})
