
import { defineComponent } from 'vue';


export default defineComponent({
    props:{
        text: {
            required: true,
            type: String,
        },
        uppercase: {
            default: false,
            type: Boolean
        }
    },
    setup(){
    
        return{
        
        }
    }

})

