
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        title: { type: String, default: '' },
        message: { type: String, default: '' },
        visible: { type: Boolean, default: true },
        type: { type: String, default: 'accept' },

    },
    setup(props, { emit }) {
        const handleOk = () => {
            emit('accept');
        };
        const handleCancel = () => {
            emit('close');
        };
        return {
            handleOk, handleCancel
        };
    },
});
