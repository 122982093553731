import { User } from "../auth/types";

export class BaseRegion {
    constructor(
        public id: number | undefined = undefined,
        public abbreviation: string | undefined = undefined,
        public name: string | undefined = undefined
    ) { }
}

export class BaseProvinceRegion {
    constructor(
        public id: number | undefined = undefined,
        public name: string | undefined = undefined,
        public region_id: number | undefined = undefined
    ) { }
}


export class BaseCountyProvince {
    constructor(
        public id: number | undefined = undefined,
        public province_id: number | undefined = undefined,
        public name: string | undefined = undefined
    ) { }
}