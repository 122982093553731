import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex bg-gradient-to-br from-success/50 to-success/100 rounded-xl" }
const _hoisted_2 = { class: "my-10" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ManagementTab = _resolveComponent("ManagementTab")!
  const _component_CommunityForm = _resolveComponent("CommunityForm")!
  const _component_BlocksTemplate = _resolveComponent("BlocksTemplate")!
  const _component_PermissionRole = _resolveComponent("PermissionRole")!
  const _component_BlockManagement = _resolveComponent("BlockManagement")!
  const _component_QueriesCommunity = _resolveComponent("QueriesCommunity")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ManagementTab, {
        content: "General",
        "round-left": true,
        selected: _ctx.showGeneral,
        "onUpdate:selected": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showGeneral) = $event)),
        onClick: _cache[1] || (_cache[1] = () => { _ctx.turnOffAll('community-general'); _ctx.showGeneral = true })
      }, null, 8, ["selected"]),
      _createVNode(_component_ManagementTab, {
        content: "Edificios",
        selected: _ctx.showBuildings,
        "onUpdate:selected": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showBuildings) = $event)),
        onClick: _cache[3] || (_cache[3] = () => { _ctx.turnOffAll('community-block'); _ctx.showBuildings = true })
      }, null, 8, ["selected"]),
      _createVNode(_component_ManagementTab, {
        content: "Usuarios y rol",
        selected: _ctx.showUserRole,
        "onUpdate:selected": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showUserRole) = $event)),
        onClick: _cache[5] || (_cache[5] = () => { _ctx.turnOffAll('community-permission-role'); _ctx.showUserRole = true })
      }, null, 8, ["selected"]),
      _createVNode(_component_ManagementTab, {
        content: "Administración",
        selected: _ctx.showManage,
        "onUpdate:selected": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showManage) = $event)),
        onClick: _cache[7] || (_cache[7] = () => { _ctx.turnOffAll('community-management'); _ctx.showManage = true })
      }, null, 8, ["selected"]),
      _createVNode(_component_ManagementTab, {
        content: "Consultas",
        "round-right": true,
        selected: _ctx.showQuery,
        "onUpdate:selected": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showQuery) = $event)),
        onClick: _cache[9] || (_cache[9] = () => { _ctx.turnOffAll('community-queries'); _ctx.showQuery = true })
      }, null, 8, ["selected"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.pathName == 'community-general')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_CommunityForm, { communityId: _ctx.localCommunityId }, null, 8, ["communityId"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.pathName == 'community-block')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_BlocksTemplate, { communityId: _ctx.localCommunityId }, null, 8, ["communityId"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.pathName == 'community-permission-role')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_PermissionRole, { communityId: _ctx.localCommunityId }, null, 8, ["communityId"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.pathName == 'community-management')
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_BlockManagement, { communityId: _ctx.localCommunityId }, null, 8, ["communityId"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.pathName == 'community-queries')
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_QueriesCommunity, { communityId: _ctx.localCommunityId }, null, 8, ["communityId"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}