

import { defineComponent, ref } from "vue";

import Accordion from '../atoms/Accordion.vue';
import ComponentCard from "./ComponentCard.vue";
export default defineComponent({
    props: {
        instructionResident: {
            type: Array as () => any[],
            required: true
        },
        instructionPersonal: {
            type: Array as () => any[],
            required: true
        }
    },
    components: { Accordion, ComponentCard },
    setup(props) {
        const formattedInstructionPersonal = ref<{ title: string, content: string }[]>([]);
        const formattedInstructionResident = ref<{ title: string, content: string }[]>([]);
        const InstructionPersonals = ref(props.instructionPersonal);
        const InstructionResident = ref(props.instructionResident);

        const obtainData = () => {
            const uniqueTypesPersonal = [...new Set(InstructionPersonals.value.map(uniqueId => uniqueId.emergency_type_id))];
            formattedInstructionPersonal.value = uniqueTypesPersonal.map((type) => {
                const contentList = InstructionPersonals.value.filter(allContent => allContent.emergency_type_id === type).map(res => res.content);
                const content = contentList.map((content, index) => `<li><strong>${index + 1}.</strong> ${content}</li>`).join("");
                const typeName = type === 1 ? 'Instrucción en caso de fuego' : type === 2 ? 'Instrucción en caso de Terremoto' : type === 3 ? 'Instrucción en caso de Tsunami' : 'Instrucción en caso de simulacro'
                return { title: `${typeName}`, content: `<ol style="text-align: left;">${content}</ol>` };
            });

            const uniqueTypesResident = [...new Set(InstructionResident.value.map(uniqueId => uniqueId.emergency_type_id))];
            formattedInstructionResident.value = uniqueTypesResident.map((type) => {
                const contentList = InstructionResident.value.filter(allContent => allContent.emergency_type_id === type).map(res => res.content);
                const content = contentList.map((content, index) => `<li><strong>${index + 1}.</strong> ${content}</li>`).join("");
                const typeName = type === 1 ? 'Instrucción en caso de fuego' : type === 2 ? 'Instrucción en caso de Terremoto' : type === 3 ? 'Instrucción en caso de Tsunami' : 'Instrucción en caso de simulacro'
                return { title: `${typeName}`, content: `<ol style="text-align: left;">${content}</ol>` };
            });
        };

        obtainData();

        return {
            formattedInstructionPersonal,
            InstructionPersonals,
            formattedInstructionResident

        };
    },
});
