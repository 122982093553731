
import Modal from '../../../components/general/molecules/Modal.vue'
import { defineComponent, ref, onMounted, computed } from 'vue';
import { FormService } from "@/services/form/FormServices";
import { BaseForm, Question } from '@/services/form/types';
import TextField from "@/components/general/atoms/TextField.vue";
import TableBase from '@/components/general/molecules/TableBase.vue';
import Selector from '../../general/atoms/Selector.vue';
import DetailsModalForm from './DetailsModalForm.vue';

export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        communityId: {
            type: Number,
            required: true
        },
        blockId: {
            type: Number,
            required: true
        },
        visible: {
            type: Boolean,
            default: false,
        },

    },
    setup(props, { emit }) {
        const formsQuestions = ref<BaseForm>();
        const questions = ref<Question[]>([]);
        const formService = new FormService();
        const formTitle = ref<string>('');
        const tipo = ref<number>(1);
        const tipoSelector = ref<number>();
        const responseRadio = ref<boolean>();
        const title = ref<string>('');
        const message = ref<string>('');
        const visible = ref<boolean>(false);
        const showDetails = ref<boolean>(false);
        const indexDetails = ref<number>(0);
        const answers = ref<any[]>();
        const showModal = ref<boolean>(props.visible)
        console.log(showModal.value)
        function cancelModal() {
            showModal.value = false
            emit('closeModal')
        }
        const dataAnswer = computed(() => {
            return {
                answers: Array(questions.value.length).fill(''),
                answersDetailResident: Array(questions.value.length).fill([]),
                answersDetailPersonnel: Array(questions.value.length).fill([]),
                answersValueResident: Array(questions.value.length).fill([]),
                answersValuePersonnel: Array(questions.value.length).fill([]),
            }
        })
        const formSelector = ref([
            { value: 2, label: 'Formulario por defecto' },
        ])

        onMounted(async () => {
            init()
        });

        const handleAccept = () => {
            visible.value = false
        }
        const handleCloseSuccess = () => {
            visible.value = false
        }
        const init = async () => {
            const response = await formService.getFormQuestionService(2);
            formsQuestions.value = response.data;
            formTitle.value = formsQuestions.value.description;
            questions.value = response.data.questions.sort((a, b) => {
                if (a.index === undefined && b.index === undefined) {
                    return 0;
                } else if (a.index === undefined) {
                    return 1;
                } else if (b.index === undefined) {
                    return -1;
                } else {
                    return a.index - b.index;
                }
            });
            console.log(questions.value)
        }

        function onUpdateModel(value: any) {
            tipo.value = value;
            init()
        }
        function onUpdateSelector(value: number) {
            tipoSelector.value = Number(value);
        }
        function details(index: number) {
            indexDetails.value = index
            showDetails.value = true
        }
        const responseUpdate = (data: any, index: number) => {
            showDetails.value = false
            dataAnswer.value.answersDetailPersonnel[index] = data.answersDetailPersonnel.map((item: any) => {
                return item.content
            })
            dataAnswer.value.answersDetailResident[index] = data.answersDetailResident.map((item: any) => {
                return item.content
            })
            dataAnswer.value.answersValuePersonnel[index] = data.answersDetailPersonnel.map((item: any) => {
                return item.title
            })
            dataAnswer.value.answersValueResident[index] = data.answersDetailResident.map((item: any) => {
                return item.title
            })
        }

        async function saveForm() {
            answers.value = questions.value.map((question) => {
                if (question.responseForm === "true") {
                    return true;
                }
                else if (question.responseForm === "false") {
                    return false;
                }
                else if (!isNaN(Number(question.responseForm))) {
                    return Number(question.responseForm);
                }
                else {
                    return question.responseForm;
                }
            });
            const data = {
                answers: answers.value,
                answersDetailsResident: dataAnswer.value.answersDetailResident,
                answersParametersResident: dataAnswer.value.answersValueResident,
                answersDetailsPersonnel: dataAnswer.value.answersDetailPersonnel,
                answersParametersPersonnel: dataAnswer.value.answersValuePersonnel,
                answersValueResident: answers.value.map(value => value === true ? 1 : 0),
                answersValuePersonnel: answers.value.map(value => value === true ? 1 : 0)
            }
            formService.updateAnswerDetails(props.communityId, props.blockId, data)
            cancelModal()
        }

        return {
            title,
            showModal,
            message,
            questions,
            formTitle,
            tipo,
            responseRadio,
            tipoSelector,
            formSelector,
            showDetails,
            indexDetails,
            dataAnswer,
            onUpdateModel,
            onUpdateSelector,
            handleAccept,
            handleCloseSuccess,
            details,
            saveForm,
            responseUpdate,
            cancelModal
        };

    },
    components: { Modal, TableBase, TextField, Selector, DetailsModalForm }
})
