
import { defineComponent, onMounted, ref } from 'vue';
import { CommunityService } from "@/services/communities/CommunityServices";
import { mdiGamepadRoundRight } from '@mdi/js';

export default defineComponent({
    props: {
        iconName: {
            type: String,
            default: '',
        },
        communityId: {
            type: Number,
            default: 0,
        },
        block: {
            type: Number,
            default: 0,
        },
        floors: {
            type: Number,
            default: 0,
        },
        parking: {
            type: Number,
            default: 0,
        },
        resident: {
            type: Number,
            default: 0,
        },
        roundTop: {
            default: false
        },
        roundDown: {
            default: false
        },
        roundLeft: {
            default: false
        },
        roundRight: {
            default: false
        },
    },
    setup(props) {
        const communityService = new CommunityService();
        const content = 10
        const icon = 'bell'
        const borderTop = true
        const borderDown = true
        const titleText = ref<string>('')
        const arrayCount = ref<number>(0)
        onMounted(init);

        async function init() {
            if (props.block === 0) {
                const response = await communityService.getCommunityBlocks(props.communityId);
                arrayCount.value = Array.isArray(response.data) ? response.data.length : 0;
                titleText.value = `Número de torres: ${arrayCount.value}`;
            } else {
                if (props.iconName === 'account-group') {
                    const response = await communityService.getResidentblock(props.communityId, props.block);
                    arrayCount.value = Array.isArray(response.data) ? response.data.length : 0;
                    titleText.value = `Cantidad de residentes: 1000`;
                } else if (props.iconName === 'office-building-outline') {
                    arrayCount.value = props.floors
                    titleText.value = `Número de pisos: ${props.floors}`;
                } else if (props.iconName === 'car-shift-pattern') {
                    arrayCount.value = props.parking
                    titleText.value = `Número de estacionamientos: ${props.parking}`;
                }

            }


        }

        return {
            content,
            icon,
            borderTop,
            borderDown,
            titleText,
            arrayCount
        }
    }
})
