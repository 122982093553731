
import AddButton from '@/components/general/atoms/AddButton.vue';
import { defineComponent, ref, computed, watch } from 'vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import { UserService } from '@/services/users/UserServices';
import { UserCommunity } from '@/services/users/types'
import Modal from '@/components/general/molecules/Modal.vue';
import Selector from '@/components/general/atoms/Selector.vue';
import { PetsInfo } from '@/services/users/types';
import TableComponent from '@/components/general/molecules/TableComponent.vue';
import { useRoute } from 'vue-router';

export default defineComponent({

  setup() {
    interface formatted {
      id: number,
      type: number,
      user: string,
      animal: string,
      userPhone: string,
      unit: number,
      userId: number
    }
    const animalSelect = [
      { value: 1, label: 'Otro' },
      { value: 2, label: 'Perro' },
      { value: 3, label: 'Gato' },
      { value: 4, label: 'Pájaro' },
      { value: 5, label: 'Reptil' },
      { value: 6, label: 'Anfibio' },
      { value: 7, label: 'Pez' },
      { value: 8, label: 'Roedor' }
    ];

    const route = useRoute();
    const communityId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0); //TODO: usar id real
    const selectAnimal = ref<number>()
    const UserServices = new UserService()
    const showAlertModal = ref<boolean>(false)
    const showModal = ref<boolean>(false)
    const loading = ref<boolean>(false)
    const userInfo = ref<UserCommunity[]>([])
    const currentItem = ref<formatted>({} as formatted);
    const selectedUser = ref(userInfo.value.map((user) => {
      return {
        value: user.id,
        label: user.user.firstName + ' ' + user.user.lastName
      }
    }))

    const selectedItem = ref<number>()
    const showModalAdd = ref<boolean>(false)
    const showModalEdit = ref<boolean>(false)
    const pets = ref<PetsInfo[]>([])

    const searchTableData = ref(['user', 'animal', 'quantity', 'userPhone']);
    const showEditAlert = ref<boolean>(false)

    const columns = ref([{ name: 'user', label: 'Usuario' }, { name: 'animal', label: 'Animal' },
    { name: 'userPhone', label: 'Telefono' }, { name: 'unit', label: 'Unidad' }]);
    const isFieldsFilled = computed(() => {
      return !!selectAnimal.value && !!selectedItem.value;
    });
    watch(() => userInfo.value, (newVal) => {
      selectedUser.value = newVal.map((user) => {
        return {
          value: user.id,
          label: user.user.firstName + ' ' + user.user.lastName
        }
      })
    })

    async function getCommunityResidents() {
      try {
        const response = await UserServices.getCommunityResidents(communityId.value);
        if (!response.success) {
          userInfo.value = [];
        }
        else {
          userInfo.value = response.data;
        }
      }
      catch (error) {
        console.log(error);
      }
    }
    const showButtons = computed(() => true)
    const formattedData = computed(() => {
      return pets.value.map((item) => {
        return {
          id: item.id,
          type: item.type,
          user: userInfo.value.find((user) => user.id === item.communityUserId)?.user.firstName + ' '
            + userInfo.value.find((user) => user.id === item.communityUserId)?.user.lastName,
          animal: animalSelect.find((animal) => animal.value === item.type)?.label,
          userPhone: userInfo.value.find((user) => user.id === item.communityUserId)?.user.phone,
          unit: item.unitId,
          userId: userInfo.value.find((user) => user.id === item.communityUserId)?.id
        }
      })
    })
    getCommunityResidents();
    async function getPets() {
      try {
        const response = await UserServices.getPets(communityId.value);
        if (!response.success) {
          pets.value = [];
        }
        else {
          pets.value = response.data
        }
      }
      catch (error) {
        console.log(error);
      }
    }

    getPets();
    function editItem() {
      console.log('edit')
    }

    function deleteOpenAlert() {
      console.log('delete')
    }

    function updateTable(index: formatted) {
      currentItem.value = index
      selectAnimal.value = (animalSelect.find(animal => animal.value === index.type)?.value)
      selectedItem.value = (index.userId)
      showModalEdit.value = true
    }
    const deleteTable = (index: formatted) => {
      currentItem.value = index
      showAlertModal.value = true
      console.log(index, 'eliminar')
    }
    const buttons = ref([
      { id: 1, name: 'Editar', action: updateTable, className: 'bg-warning text-white hover:bg-yellow-400 focus:bg-yellow-400', iconName: 'pencil' },
      { id: 2, name: 'Eliminar', action: deleteTable, className: 'bg-red-600 text-white hover:bg-red-500 focus:bg-red-500', iconName: 'delete' }
    ])
    async function handleAccept() {
      try {
        console.log(currentItem.value)
        showAlertModal.value = false;
        await UserServices.deletePets(communityId.value, currentItem.value.id);
        currentItem.value = {} as formatted;
      } catch (error) {
        console.error(error);
      }
      await getPets();
    }
    function addItem() {
      showModalAdd.value = true
      selectAnimal.value = 0
      selectedItem.value = 0
      console.log('add', pets.value)
    }
    function onUpdateModelUser(value: number) {
      selectedItem.value = value
      showEditAlert.value = false
    }
    async function saveEditPet() 
    {
      loading.value = true
      showModalEdit.value = false
      const petData = {
        id: currentItem.value.id,
        type: Number(selectAnimal.value),
        communityUserId: Number(selectedItem.value),
        unitId: currentItem.value.unit
      }
      await UserServices.updatePets(communityId.value, currentItem.value.id, petData)
      getPets()
      loading.value = false
      showEditAlert.value = true


    }
    async function savePet() {
      showModalAdd.value = false
      loading.value = true
      const petData = {
        type: Number(selectAnimal.value),
        communityUserId: Number(selectedItem.value),
      }
      await UserServices.createPets(communityId.value, petData)
      getPets()
      loading.value = false
      showEditAlert.value = true
    }
    function onUpdateModelAnimal(value: number) {
      selectAnimal.value = value
    }


    return {
      editItem,
      deleteOpenAlert,
      handleAccept,
      addItem,
      onUpdateModelUser,
      savePet,
      saveEditPet,
      onUpdateModelAnimal,
      showModal,
      selectedUser,
      selectedItem,
      showModalAdd,
      animalSelect,
      selectAnimal,
      formattedData,
      buttons,
      columns,
      showButtons,
      searchTableData,
      showEditAlert,
      showAlertModal,
      showModalEdit,
      isFieldsFilled,
      loading
    };

  },
  components: { AddButton, Modal, Selector, TableComponent, ModalAlert }
})
