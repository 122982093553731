
import { defineComponent } from 'vue';
import ComponentCard from './ComponentCard.vue';
import SearchBox from './SearchBox.vue';


export default defineComponent({
    setup() {
        return {};
    },
    props: {
        searchable:{
        default: true
        },
        title: { 
            required: true,
            type: String

        },
        fixed: {
            default: false
        }
    },
    components: { ComponentCard,  }
})

