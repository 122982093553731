import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-2 flex-none flex gap-2"
}
const _hoisted_2 = {
  key: 0,
  class: "px-2 pt-2 font-bold flex-1 truncate text-left"
}
const _hoisted_3 = { class: "overflow-auto pb-4 h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["shadow-lg border rounded-lg overflow-hidden flex flex-col", _ctx.bg_color + ' ' + _ctx.color])
  }, [
    (_ctx.$slots.header)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true)
          ]),
          _renderSlot(_ctx.$slots, "header")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}