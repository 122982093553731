
import { defineComponent, ref, watch, computed } from "vue";
import { QueriesServices } from "@/services/queries/QueriesServices";
import { QueryGeneral } from "@/services/queries/types";
import Modal from "@/components/general/molecules/Modal.vue";
import Accordion from "@/components/general/atoms/Accordion.vue";
import TextField from "@/components/general/atoms/TextField.vue";
import ModalAlert from "@/components/general/molecules/ModalAlert.vue";
import AddButton from "@/components/general/atoms/AddButton.vue";
import { useAuthStore } from "../../stores/auth";
import { useRoute } from 'vue-router';

export default defineComponent({
  components: { Accordion, Modal, TextField, ModalAlert, AddButton },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

    const route = useRoute();
    const communityId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
    const authStore = useAuthStore();
    const QueryService = new QueriesServices();
    const showAceptAlert = ref<boolean>(false);
    const showEditAlert = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const queries = ref<QueryGeneral[]>([]);
    const showModalEdit = ref<boolean>(false);
    const showModalAdd = ref<boolean>(false);
    const showAlertModal = ref<boolean>(false);
    const questionField = ref<string>("");
    const answerField = ref<string>("");
    const currentItem = ref<number>(0);
    const authSuperUser = authStore.user;
    const formattedQueries = ref<{ title: string, content: string }[]>([]);
    const isFieldsFilled = computed(() => {
      return questionField.value.trim() !== '' && answerField.value.trim() !== '';
    });
    // const formattedQueries = ref();
    async function getQueries() {
      try {
        const response = await QueryService.getQueries();
        if (!response.success) {
          queries.value = [];
        } else {
          queries.value = response.data;
          console.log(queries.value);
        }
      } catch (error) {
        console.error(error);
      }
    }
    getQueries()
    watch(queries, () => {
      formattedQueries.value = queries.value.map((query) => {
        return {
          title: query.question,
          content: query.answer
        }
      })
    })
    async function editItem(index: number) {
      showModalEdit.value = true
      answerField.value = queries.value[index].answer
      questionField.value = queries.value[index].question
      currentItem.value = index
      showEditAlert.value = false
    }
    async function saveEditItem() {
      showModalEdit.value = false
      loading.value = true
      queries.value[currentItem.value].question = questionField.value
      queries.value[currentItem.value].answer = answerField.value
      const queryWithoutId = {
        question: queries.value[currentItem.value].question,
        answer: queries.value[currentItem.value].answer,

      }
      await QueryService.updateQuery(communityId.value, queryWithoutId, queries.value[currentItem.value].id).then((response) => {
        if (!response.success) {
          return;
        }
        if (response.code === 200) {
          getQueries()
          showEditAlert.value = true
          loading.value = false
        }
      })
      currentItem.value = 0
    }
    async function addItem() {
      showModalAdd.value = true
      showAceptAlert.value = false
      answerField.value = ""
      questionField.value = ""
    }
    async function saveAddItem() {
      showModalAdd.value = false
      loading.value = true
      const queryWithoutId = {
        question: questionField.value,
        answer: answerField.value,
      }
      await QueryService.createQuery(queryWithoutId).then((response) => {
        if (!response.success) {
          return;
        }
        if (response.code === 201) {
          getQueries();
          console.log(showAceptAlert)
          showAceptAlert.value = true
          loading.value = false
        }
      })
    }
    async function deleteItem(index: number) {
      currentItem.value = index
      showAlertModal.value = true
    }
    async function handleAccept() {
      try {
        showAlertModal.value = false;
        await QueryService.deleteQuery(communityId.value, queries.value[currentItem.value].id);
        currentItem.value = 0;
      } catch (error) {
        console.error(error);
      }
      await getQueries();
    }


    return {
      queries,
      formattedQueries,
      editItem,
      addItem,
      deleteItem,
      showModalEdit,
      showModalAdd,
      questionField,
      answerField,
      currentItem,
      saveEditItem,
      saveAddItem,
      showAlertModal,
      handleAccept,
      isFieldsFilled,
      showAceptAlert,
      showEditAlert,
      authSuperUser,
      loading
    };
  },
});
