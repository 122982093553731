
import { defineComponent, ref, onMounted } from "vue";
import ComponentCard from "./ComponentCard.vue";
import TextField from "../atoms/TextField.vue";
import Selector from "../atoms/Selector.vue";
import AddButton from "../atoms/AddButton.vue";
import ModalAlert from "./ModalAlert.vue";
import FormHandleRegister from '@/components/communities/organisms/adminConfig/FormHandleRegister.vue';
import { FormService } from "@/services/form/FormServices";
import { BaseForm, Question } from '@/services/form/types';
import Alert from '../../../components/general/atoms/Alert.vue';
import { useRoute } from 'vue-router';
import { mdiCarLightAlert } from "@mdi/js";

export default defineComponent({

    components: { ComponentCard, TextField, Selector, AddButton, ModalAlert, FormHandleRegister, Alert },
    setup(prop) {
        const route = useRoute();
        const communityId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const formsQuestions = ref<BaseForm>();
        const questions = ref<Question[]>([]);
        const formService = new FormService();
        const showModal = ref<boolean>(false)
        const showEditFormModal = ref<boolean>(false)
        const showAlert = ref<boolean>(false)
        const title = ref<string>('')
        const message = ref<string>('')
        const formTitle = ref<string>('')
        let questionId = ref<number>(0)
        let formId = ref<number>(2)
        const tipoSelector = ref<number>(1)
        const formSelector = ref([
            { id: 2, label: 'Formulario por defecto' },
        ])
        onMounted(async () => {
            init()
        });
        function onUpdateSelector(value: any) {
            console.log(value)
            tipoSelector.value = value;
        }

        const init = async () => {
            const response = await formService.getFormQuestionService(2);
            formsQuestions.value = response.data;
            formTitle.value = formsQuestions.value.description;
            questions.value = response.data.questions.sort((a, b) => {
                if (a.index === undefined && b.index === undefined) {
                    return 0;
                } else if (a.index === undefined) {
                    return 1;
                } else if (b.index === undefined) {
                    return -1;
                } else {
                    return a.index - b.index;
                }
            });
        }

        const reaload = () => {
            showEditFormModal.value = false
            init()
        }

        const editForm = (id: number) => {
            questionId.value = id
            showEditFormModal.value = true
        }

        const showDeleteModal = (id: number) => {
            questionId.value = id
            title.value = '¿Desea Eliminar?'
            message.value = 'No sera posible retroceder la Acción'
            showModal.value = true
        }

        const handleCancel = () => {
            showModal.value = false;
        };

        const handleDelete = () => {
            formService.deleteFormQuestionService(questionId.value)
                .then((response) => {
                    if (response.code === 204) {
                        showModal.value = false;
                        init()
                    }
                })
                .catch((error) => {
                    console.error(error);
                })

        }

        const determineAnwerType = async (question: any) => {
            let answerType
            let responseFloat: number = parseFloat(question.responseForm);
            if (!isNaN(responseFloat)) {
                if (Number.isInteger(responseFloat)) {
                    answerType = 1
                } else {
                    answerType = 2
                }
            } else if (question.responseForm.toLowerCase() === "verdadero" || question.responseForm.toLowerCase() === "falso") {
                answerType = 0
            } else {
                answerType = 3
            }
            await formService.updateAnswerType(communityId.value, question.id, question.formId, answerType)
                .then((response) => {
                    if (response.code === 200) {
                        showModal.value = false;
                        message.value = "Tipo de Dato Guardado con exito!";
                        showAlert.value = true;
                        init()
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setTimeout(() => {
                        showAlert.value = false;
                    }, 4000);
                })
        };

        const handleAccept = () => {
            showModal.value = false;
        };

        return {
            showDeleteModal,
            showModal,
            title,
            message,
            handleCancel,
            handleDelete,
            handleAccept,
            editForm,
            showEditFormModal,
            questions,
            reaload,
            formTitle,
            determineAnwerType,
            questionId,
            showAlert,
            tipoSelector,
            formSelector,
            onUpdateSelector,

        };
    },
});
