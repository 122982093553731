
import { ref } from "@vue/reactivity";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        itemParameter: {
            type: String,
            default:"",
            required: false
        },
        array: {
            type: Object
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        iconName: {
            type: String,
            required: false,
        },
        isAcopled: {
            type: Boolean,
            required: false,
            default: false,
        },
        compact: {
            type: Boolean,
            required: false,
            default: false,
        },

    },
    emits: ["add", "drop"],
    setup(props, { emit }) {
        // State
        const item = ref("");

        function removeItem(itemIndex: number) {
            emit("drop", itemIndex);
        }

        //Export State
        return {
            item,
            removeItem,
        };
    },
});
