
import { computed, defineComponent, ref } from "vue";
import { RouterView, useRouter } from "vue-router";
import "./assets/tailwind.css"
import Topbar from "./components/general/organisms/Topbar.vue";
import { AuthService } from "./services/auth/AuthServices";
import Navbar from "./components/layout/NavBar.vue";

export default defineComponent({
  setup(props, ctx) {
    const router = useRouter()
    const authService = new AuthService()
    const showTopBar = computed(() => router.currentRoute.value.name != 'login')
    const showNavbar = computed(() => router.currentRoute.value.name != 'login');

    async function checkSession() {
      const hasValidSession = await authService.hasValidUserSession()
      if (hasValidSession) {
        const currentRoute = router.currentRoute.value
        if (currentRoute.name && currentRoute.params) {
          router.replace({ name: currentRoute.name, params: currentRoute.params })
        }
      }
      else {
        router.push({ name: 'login' })
      }
    }


    checkSession()

    return {
      showTopBar,
      showNavbar
    };
  },
  // components: { Topbar }
  components: { Navbar }
})
