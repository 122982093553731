import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full w-full justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableComponent = _resolveComponent("TableComponent")!
  const _component_EmployeeRegister = _resolveComponent("EmployeeRegister")!
  const _component_ModalAlert = _resolveComponent("ModalAlert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TableComponent, {
      title: 'Trabajadores',
      sectionAddButton: 'employee',
      items: _ctx.objetosModificados,
      columns: _ctx.columns,
      buttons: _ctx.buttons,
      showButtons: _ctx.showButtons,
      searchTableData: _ctx.searchTableData,
      onCloseAction: _ctx.closeModal
    }, null, 8, ["items", "columns", "buttons", "showButtons", "searchTableData", "onCloseAction"]),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_EmployeeRegister, {
          key: 0,
          commiteUser: _ctx.commiteUser,
          action: _ctx.action,
          visible: _ctx.showModal,
          onCloseModal: _ctx.closeModal
        }, null, 8, ["commiteUser", "action", "visible", "onCloseModal"]))
      : _createCommentVNode("", true),
    (_ctx.showModalAlert)
      ? (_openBlock(), _createBlock(_component_ModalAlert, {
          key: 1,
          title: _ctx.title,
          message: _ctx.message,
          visible: _ctx.showModalAlert,
          option: false,
          onAccept: _ctx.handleAccept,
          onDeleteItem: _ctx.handleDelete,
          onCancelAlert: _ctx.handleCancel
        }, null, 8, ["title", "message", "visible", "onAccept", "onDeleteItem", "onCancelAlert"]))
      : _createCommentVNode("", true)
  ]))
}