
import AddButton from '@/components/general/atoms/AddButton.vue';
import { defineComponent, ref, computed } from 'vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import TableComponent from '@/components/general/molecules/TableComponent.vue';
import Modal from '@/components/general/molecules/Modal.vue';
import TextField from '@/components/general/atoms/TextField.vue';
import Selector from '@/components/general/atoms/Selector.vue';
import { GasCylinder, Unit } from '@/services/communities/types';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
    setup() {
        const route = useRoute();
        const router = useRouter();
        const communityId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const CommunityServices = new CommunityService();
        const charge = ref<number>(0);
        const quantity = ref<number>(0);
        const showModal = ref<boolean>(false);
        const showAlert = ref<boolean>(false);
        const showModalAdd = ref<boolean>(false)
        const showGasRegister = ref<boolean>(false)
        const GasCylinderData = ref<GasCylinder[]>([]);
        const units = ref<Unit[]>([]);
        const unitList = ref()
        const selectedUnit = ref<number>(0);
        const currentItem = ref<GasCylinder>({} as GasCylinder);
        const showAlertModal = ref<boolean>(false)
        const showModalEdit = ref<boolean>(false)
        const showButtons = computed(() => true)
        const showEditAlert = ref<boolean>(false)
        const searchTableData = ref(['address']);
        const loading = ref<boolean>(false)
        const columns = ref([{ name: 'charge', label: 'Carga' }, { name: 'quantity', label: 'Cantidad' }, { name: 'address', label: 'Unidad' }, { name: 'floor', label: 'Piso' }]);
        const isFieldsFilled = computed(() => {
            return !!charge.value && !!quantity.value && !!selectedUnit.value;
        });
        async function getGasCylinders() {
            try {
                const response = await CommunityServices.getGasCylindersCommunity(communityId.value);
                if (!response.success) {
                    GasCylinderData.value = [];
                }
                else {
                    GasCylinderData.value = response.data;
                }
            }
            catch (error) {
                console.log(error);
            }
        }
        async function getUnits() {
            try {
                const response = await CommunityServices.getUnitsCommunity(communityId.value);
                if (response.success) {
                    units.value = response.data;
                    unitList.value = units.value.map((unit) => ({ value: unit.id, label: unit.address }));
                }

            }
            catch (error) {
                console.log(error);
            }
        }
        getUnits();
        getGasCylinders();
        const formattedData = computed(() => {
            return GasCylinderData.value.map((item) => {
                return {
                    id: item.id,
                    charge: item.charge,
                    quantity: item.quantity,
                    address: units.value.find((unit) => unit.id === item.unitId)?.address,
                    floor: units.value.find((unit) => unit.id === item.unitId)?.floor
                }
            })
        })

        async function addItem() {
            showModalAdd.value = true
            charge.value = 0
            quantity.value = 0
            selectedUnit.value = 0

        }
        async function saveEditItem() {
            loading.value = true
            showModalEdit.value = false
            const GasCylinder = { charge: charge.value, quantity: quantity.value };
            await CommunityServices.updateGasCylinder(communityId.value, blockId.value, currentItem.value.id, GasCylinder);
            showEditAlert.value = true
            showGasRegister.value = true
            loading.value = false
            getGasCylinders();
        }
        const updateTable = (index: GasCylinder) => {
            currentItem.value = index
            showModalEdit.value = true
            charge.value = index.charge
            quantity.value = index.quantity
            selectedUnit.value = index.unitId
            showEditAlert.value = false
            console.log(index, 'updateTable')
        }
        const deleteTable = (index: GasCylinder) => {
            currentItem.value = index
            showAlertModal.value = true
            console.log(index, 'eliminar')
        }
        async function handleAccept() {
            try {
                showAlertModal.value = false;
                await CommunityServices.deleteGasCylinder(communityId.value, blockId.value, currentItem.value.id);
                currentItem.value = {} as GasCylinder;
            } catch (error) {
                console.error(error);
            }
            await getGasCylinders();
        }
        async function saveGasCylinder() {
            console.log(selectedUnit.value, "asas")
            const GasCylinder = {
                charge: charge.value,
                quantity: quantity.value,
                unitId: selectedUnit.value
            }
            await CommunityServices.createGasCylinder(communityId.value, blockId.value, GasCylinder);
            showModalAdd.value = false
            getGasCylinders()
            showEditAlert.value = true
        }
        function onUpdateModelUnit(value: number) {
            selectedUnit.value = value;
        }
        const buttons = ref([
            { id: 1, name: 'Editar', action: updateTable, className: 'bg-warning text-white hover:bg-yellow-400 focus:bg-yellow-400', iconName: 'pencil' },
            { id: 2, name: 'Eliminar', action: deleteTable, className: 'bg-red-600 text-white hover:bg-red-500 focus:bg-red-500', iconName: 'delete' }
        ])

        const back = () => {
            router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/census` });
        }
        return {
            back,
            saveEditItem,
            handleAccept,
            addItem,
            saveGasCylinder,
            onUpdateModelUnit,
            CommunityServices,
            showAlert,
            showModal,
            showGasRegister,
            showModalAdd,
            showModalEdit,
            formattedData,
            showEditAlert,
            columns,
            buttons,
            showButtons,
            searchTableData,
            showAlertModal,
            isFieldsFilled,
            charge,
            quantity,
            units,
            selectedUnit,
            unitList,
            loading
        };
    },
    components: { AddButton, ModalAlert, TableComponent, Modal, TextField, Selector }
})
