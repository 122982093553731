
import { defineComponent } from 'vue';
import ComponentCard from '@/components/general/molecules/ComponentCard.vue';


export default defineComponent({
    setup() {
        
        return {  };
    },
    components: { ComponentCard }
})
