
import { defineComponent, ref, toRef } from 'vue';
import AdminRegister from '../../communities/organisms/adminConfig/AdminRegister.vue'
import EmployeeRegister from '../../communities/organisms/adminConfig/EmployeeRegister.vue'
import ExtinguisherRegister from '../../../components/communities/organisms/adminConfig/ExtinguisherRegister.vue'
import ResidentRegister from '../../../components/communities/organisms/adminConfig/ResidentRegister.vue'
import PetRegister from '../../../components/communities/organisms/adminConfig/PetRegister.vue'
import GasRegister from '../../../components/communities/organisms/adminConfig/GasRegister.vue'
import FormHandleRegister from '@/components/communities/organisms/adminConfig/FormHandleRegister.vue';

export default defineComponent({
    props: {
        section: {
            type: String,
            default: '',
        },
        action: {
            type: String,
            default: '',
        }
    },
    setup(props, { emit }) {
        const sectioModify = toRef(props, "section");
        const showAdminRegister = ref(false)
        const showEmployeeRegister = ref(false)
        const showResidentRegister = ref(false)
        const showExtinguisherRegister = ref(false)
        const showPetRegister = ref(false)
        const showGasRegister = ref(false)
        const showHandleRegister = ref(false)
        const keyIndexInvestModal = ref(0);
        const typeMethods = ref(props.action);

        function reload() {
            keyIndexInvestModal.value++;
        }

        function openAdd() {
            switch (sectioModify.value) {
                case "admin":
                    showAdminRegister.value = true;
                    break;
                case "employee":
                    showEmployeeRegister.value = true;
                    break;
                case "resident":
                    showResidentRegister.value = true;
                    break;
                case "extinguisher":
                    showExtinguisherRegister.value = true;
                    break;
                case "pet":
                    showPetRegister.value = true;
                    break;
                case "gas":
                    showGasRegister.value = true;
                    break;
                case "form-register":
                    showHandleRegister.value = true;
                    break;
            }
        }

        const closeAction = () => {
            showAdminRegister.value = false;
            showEmployeeRegister.value = false;
            showResidentRegister.value = false;
            showExtinguisherRegister.value = false;
            showPetRegister.value = false
            showGasRegister.value = false;
            showHandleRegister.value = false;
            emit('closeAction')
        }


        return {
            openAdd,
            reload,
            closeAction,
            showAdminRegister,
            showEmployeeRegister,
            showResidentRegister,
            showExtinguisherRegister,
            showPetRegister,
            showGasRegister,
            keyIndexInvestModal,
            typeMethods,
            showHandleRegister

        }
    },
    components: {
        AdminRegister,
        EmployeeRegister,
        ExtinguisherRegister,
        ResidentRegister,
        PetRegister,
        GasRegister,
        FormHandleRegister,


    }

})

