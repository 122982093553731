
import { defineComponent, ref, computed } from 'vue';
import Modal from '@/components/general/molecules/Modal.vue';
import TableComponent from '@/components/general/molecules/TableComponent.vue';
export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: () => {
                return {};
            }
        },
    },
    setup(props, { emit }) {
        const showModal = ref<boolean>(props.visible || false)
        const searchTableData = ref(['firsName', 'name'])
        const showButtons = computed(() => false)
        const columns = ref([{ name: 'description', label: 'Descripción' }])
        const establishments = [
            { value: 1, label: 'Oficina' },
            { value: 2, label: 'Residencia' },
            { value: 3, label: 'Local Comercial' },
        ];
        const closeModal = () => {
            showModal.value = false
            emit('closeModal')
        };

        return {
            closeModal,
            showModal,
            columns,
            showButtons,
            searchTableData,
        };
    },
    components: { Modal, TableComponent, },
});

