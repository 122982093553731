import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import CommunityView from "../views/CommunityView.vue";
import LoginView from "@/views/auth/LoginView.vue";
import RegisterView from "@/views/auth/RegisterView.vue";
import MyCommunityView from "@/views/menu/MyCommunityView.vue";
import InstructionView from "@/views/menu/InstructionView.vue";
import QueryView from "@/views/menu/QueryView.vue";
import HelpView from "@/views/help/HelpView.vue";
import CommunityForm from "@/components/communities/organisms/CommunityForm.vue";
import BlocksTemplate from "@/components/communities/organisms/BlocksTemplate.vue";
import PermissionRole from "@/components/communities/organisms/PermissionRole.vue";
import BlockManagement from "@/components/communities/organisms/CommunityManagement.vue";
import QueriesCommunity from "@/components/communities/organisms/QueriesCommunity.vue";
import EmployeesTable from "@/components/communities/organisms/tables/EmployeesTable.vue";
import ResidentsTable from "@/components/communities/organisms/tables/ResidentsTable.vue";
import FireExtinguisherTable from "@/components/communities/organisms/tables/FireExtinguisherTable.vue";
import GasCylindersTable from "@/components/communities/organisms/tables/GasCylindersTable.vue";
import { useAuthStore } from "@/stores/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    component: LoginView,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
  },
  {
    path: "/my-communities",
    name: "my-communities",
    component: MyCommunityView,
  },
  {
    path: "/my-instructions",
    name: "my-instructions",
    component: InstructionView,
  },
  {
    path: "/my-queries",
    name: "my-queries",
    component: QueryView,
  },
  {
    path: "/help",
    name: "help",
    component: HelpView,
  },
  {
    path: "/community/:id",
    component: CommunityView,
    props: true,
    children: [
      {
        path: "community-general",
        name: "community-general",
        component: CommunityForm,
      },
      {
        path: "community-block",
        name: "community-block",
        component: BlocksTemplate,
      },
      {
        path: "community-permission-role",
        name: "community-permission-role",
        component: PermissionRole,
      },
      {
        path: "community-management",
        name: "community-management",
        component: BlockManagement,
      },
      {
        path: "community-queries",
        name: "community-queries",
        component: QueriesCommunity,
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach(async (to, from) => {
  const userStore = useAuthStore();
 if (userStore.userIsAuthenticated && to.path === '/' && userStore.user?.isSuperuser) {
    return { name: "my-communities" };
  }
});

export default router;
