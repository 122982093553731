export interface BaseGeneralQuery {
    id: number
    question: string
    answer: string
}

export class QueryGeneral implements BaseGeneralQuery {
    id!: number
    question!: string
    answer!: string
}

export class QueryWithOutIdGeneral {
    question!: string
    answer!: string
}

export class QueryWithOutId {
    question!: string
    answer!: string
}



export interface BaseQuery {
    id: number
    question: string
    answer: string
    communityId: number;
  }
  
  export class Query implements BaseQuery {
    id!: number
    question!: string
    answer!: string
    communityId!: number;
  }