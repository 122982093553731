import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex w-full items-end bg-gradient-to-br from-success/50 to-success/100 rounded-xl" }
const _hoisted_2 = { class: "pt-6" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { key: 4 }
const _hoisted_8 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ManagementTab = _resolveComponent("ManagementTab")!
  const _component_EmployeesTable = _resolveComponent("EmployeesTable")!
  const _component_ResidentsTable = _resolveComponent("ResidentsTable")!
  const _component_PetsTable = _resolveComponent("PetsTable")!
  const _component_ResidentsInactiveTable = _resolveComponent("ResidentsInactiveTable")!
  const _component_FireExtinguisherTable = _resolveComponent("FireExtinguisherTable")!
  const _component_GasCylindersTable = _resolveComponent("GasCylindersTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ManagementTab, {
        content: "Nomina de personal",
        "round-left": true,
        selected: _ctx.showEmployees,
        "onUpdate:selected": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showEmployees) = $event)),
        onClick: _cache[1] || (_cache[1] = () => { _ctx.turnOffAll(); _ctx.showEmployees = true })
      }, null, 8, ["selected"]),
      _createVNode(_component_ManagementTab, {
        content: "Residentes",
        selected: _ctx.showResidents,
        "onUpdate:selected": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showResidents) = $event)),
        onClick: _cache[3] || (_cache[3] = () => { _ctx.turnOffAll(); _ctx.showResidents = true })
      }, null, 8, ["selected"]),
      _createVNode(_component_ManagementTab, {
        content: "Residentes Inactivos",
        selected: _ctx.showResidentsInactive,
        "onUpdate:selected": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showResidentsInactive) = $event)),
        onClick: _cache[5] || (_cache[5] = () => { _ctx.turnOffAll(); _ctx.showResidentsInactive = true })
      }, null, 8, ["selected"]),
      _createVNode(_component_ManagementTab, {
        content: "Extintores",
        selected: _ctx.showFireExtinguishers,
        "onUpdate:selected": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showFireExtinguishers) = $event)),
        onClick: _cache[7] || (_cache[7] = () => { _ctx.turnOffAll(); _ctx.showFireExtinguishers = true })
      }, null, 8, ["selected"]),
      _createVNode(_component_ManagementTab, {
        content: "Cilindros de gas",
        "round-right": true,
        selected: _ctx.showGasCylinder,
        "onUpdate:selected": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showGasCylinder) = $event)),
        onClick: _cache[9] || (_cache[9] = () => { _ctx.turnOffAll(); _ctx.showGasCylinder = true })
      }, null, 8, ["selected"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showEmployees)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_EmployeesTable, { communityId: _ctx.communityId }, null, 8, ["communityId"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.showResidents)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_ResidentsTable, {
              class: "mb-6",
              communityId: _ctx.communityId
            }, null, 8, ["communityId"]),
            _createVNode(_component_PetsTable, { communityId: _ctx.communityId }, null, 8, ["communityId"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.showResidentsInactive)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_ResidentsInactiveTable, { communityId: _ctx.communityId }, null, 8, ["communityId"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.showFireExtinguishers)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_FireExtinguisherTable, { communityId: _ctx.communityId }, null, 8, ["communityId"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.showGasCylinder)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_GasCylindersTable, { communityId: _ctx.communityId }, null, 8, ["communityId"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.showHistory)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, " Mostrando showHistory "))
        : _createCommentVNode("", true)
    ])
  ]))
}