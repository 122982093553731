import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "flex flex-col sm:flex-row mt-10 bg-gradient-to-br from-success/50 to-success/80 rounded-xl",
  style: {"width":"30%"}
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ManagementTab = _resolveComponent("ManagementTab")!
  const _component_FormsHandlerCard = _resolveComponent("FormsHandlerCard")!
  const _component_InquiriesHandleCard = _resolveComponent("InquiriesHandleCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ManagementTab, {
        class: "text-sm sm:text-base",
        content: "Formulario de registro",
        "round-left": true,
        selected: _ctx.ShowRegistrationForm,
        "onUpdate:selected": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ShowRegistrationForm) = $event)),
        onClick: _cache[1] || (_cache[1] = () => { _ctx.turnOffAll(); _ctx.ShowRegistrationForm = true })
      }, null, 8, ["selected"]),
      _createVNode(_component_ManagementTab, {
        class: "text-sm sm:text-base",
        content: "Panel de instrucciones",
        "round-right": true,
        selected: _ctx.showBuildings,
        "onUpdate:selected": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showBuildings) = $event)),
        onClick: _cache[3] || (_cache[3] = () => { _ctx.turnOffAll(); _ctx.showBuildings = true })
      }, null, 8, ["selected"])
    ]),
    _createElementVNode("div", null, [
      (_ctx.ShowRegistrationForm)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_FormsHandlerCard)
          ]))
        : _createCommentVNode("", true),
      (_ctx.showBuildings)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_InquiriesHandleCard, {
              instructionResident: _ctx.InstructionResident,
              instructionPersonal: _ctx.InstructionPersonal
            }, null, 8, ["instructionResident", "instructionPersonal"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}