
import { defineComponent, ref } from "vue";
import Alert from "./Alert.vue";

export default defineComponent({
    components: { Alert },
    emits: ["uploadFile"],
    setup(prop, { emit }) {
        const activeDropzone = ref<boolean>(false);
        const fileName = ref<string>("");
        const showAlert = ref<boolean>(false);
        const message = ref("");
        const type = ref("");
        const disableSave = ref(false);
        function switcActivationDropzone() {
            activeDropzone.value = !activeDropzone.value;
        }

        function inputFileHandler(event: Event | DragEvent, isDrag = false) {
            let target: HTMLInputElement | DragEvent;
            let file: File | undefined;
            if (isDrag) {
                activeDropzone.value = false;
                target = event as DragEvent;
                file = target.dataTransfer ? target.dataTransfer?.files[0] : undefined;
            } else {
                target = event.target as HTMLInputElement;
                file = target.files ? target.files[0] : undefined;
            }
            if (file == undefined) {
                return false;
            }

            // Leer la imagen cargada usando FileReader
            const reader = new FileReader();
            reader.readAsDataURL(file);

            // Verificar la extensión del archivo
            const allowedExtensions = ["image/jpeg", "image/jpg", "image/png"];
            if (!allowedExtensions.includes(file?.type)) {
                showAlert.value = true;
                message.value = "Formato de archivo no válido. Solo se permiten archivos JPG y PNG.";
                type.value = "error";
                fileName.value = "";
                return false;
            }

            fileName.value = file?.name as string;
            showAlert.value = false;
            emit("uploadFile", file);
        }

        return { activeDropzone, fileName, type, message, showAlert, switcActivationDropzone, inputFileHandler };
    },
});
