
import { defineComponent, ref } from "vue";
import { useAuthStore } from '../../stores/auth'
import CommunityCard from "@/components/communities/molecules/CommunityCard.vue";
import NewCommunityCard from "@/components/communities/molecules/NewCommunityCard.vue";
import SearchBox from '@/components/general/molecules/SearchBox.vue';
import { CommunityService } from "@/services/communities/CommunityServices";
import { CommunitySummary } from "@/services/communities/types";
import ContactMe from "@/components/general/organisms/ContactMe.vue";
import CommunityRegister from "@/components/communities/organisms/adminConfig/CommunityRegister.vue";

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const communityService = new CommunityService();
    const authStore = useAuthStore();

    const showModal = ref<boolean>(false);
    const communities = ref<CommunitySummary[]>();
    const searchText = ref('');
    const authSuperUser = authStore.user;
    async function getCommunities() {
      if (authStore.user?.isSuperuser){
        const response = await communityService.getAdminCommunities();
        communities.value = response.data.filter((community: CommunitySummary) =>
        community.name.toLowerCase().includes(searchText.value.toLowerCase())
      );
      }
      else{
        const response = await communityService.getCommunitiesUser();
        communities.value = response.data.filter((community: CommunitySummary) =>
        community.name.toLowerCase().includes(searchText.value.toLowerCase())
      );
      }

    }

    getCommunities();

    const openModalCreate = () => {
      showModal.value = true;
    };

    const handleAccept = () => {
      getCommunities();
      showModal.value = false;
    };

    const handleSearch = (event: InputEvent) => {
      const value = (event.target as HTMLInputElement).value;
      searchText.value = value;
      getCommunities();
    };

    return {
      showModal,
      authSuperUser,
      communities,
      openModalCreate,
      handleAccept,
      searchText,
      handleSearch,
      authStore
    };
  },
  components: { CommunityCard, NewCommunityCard, SearchBox, ContactMe, CommunityRegister }
});
