
import { defineComponent, ref, toRef } from 'vue';
import Modal from '../../../general/molecules/Modal.vue'
import TextField from '../../../general/atoms/TextField.vue'
import Alert from '../../../general/atoms/Alert.vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';

export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        action: {
            type: String,
            default: '',
        },
        itemId: {
            type: Number,
            default: 0,
        }
    },
    setup(props, { emit }) {
        let tableData = ref([
            {
                id: 1,
                cargo: "Gerente",
                nombre: "Juan Pérez",
                depto: "Ventas",
                fab: "123",
                email: "juan.perez@ejemplo.com",
            },
            {
                id: 2,
                cargo: "Director",
                nombre: "Ana García",
                depto: "Marketing",
                fab: "456",
                email: "ana.garcia@ejemplo.com",
            },
            {
                id: 3,
                cargo: "Jefe",
                nombre: "Luis González",
                depto: "Producción",
                fab: "789",
                email: "luis.gonzalez@ejemplo.com",
            }
        ]);
        let cargo = ref('');
        let nombre = ref('');
        let departamento = ref('');
        let fab = ref('');
        let email = ref('');
        const showModal = ref(props.visible);
        const showAlert = ref(false)
        const message = ref("");
        const type = ref("");
        const loading = ref(false);
        const title = ref("");
        const idItem = ref(props.itemId)
        const showModalAlert = ref(false);
        valueUpdate();

        function valueUpdate() {
            if (props.action == 'update') {
                const getUser = tableData.value.filter((item) => item.id === idItem.value)
                cargo.value = getUser[0].cargo
                nombre.value = getUser[0].nombre
                departamento.value = getUser[0].depto
                fab.value = getUser[0].fab
                email.value = getUser[0].email
            }
        }


        function cancelModal() {
            emit('update:visible', false);
            emit('update');
        }

        function addOpenAlert() {
            showModal.value = false
            showModalAlert.value = true
        }

        function validateForm() {
            if (!cargo.value || !nombre.value || !departamento.value || !fab.value || !email.value) {
                return false
            } else {
                return true
            }
        }

        function saveBlock() {
            loading.value = true
            const validate = validateForm()
            if (validate) {
                if (props.action == 'create') {
                    message.value = "Datos Guardados con Exito";
                    type.value = "success";
                    console.log('Guardando bloque:', { cargo: cargo.value, nombre: nombre.value, departamento: departamento.value, fab: fab.value, email: email.value });
                    addOpenAlert()
                } else if (props.action == 'update') {
                    message.value = "Datos Editados con Exito";
                    type.value = "success";
                    console.log('Editando bloque:', { cargo: cargo.value, nombre: nombre.value, departamento: departamento.value, fab: fab.value, email: email.value });
                    addOpenAlert()
                }


            } else {
                message.value = "Debe rellenar todos los campos";
                type.value = "warning";
                showAlert.value = true;
                loading.value = false
            }
            setTimeout(() => {
                showAlert.value = false;
            }, 4000);
        }

        return {
            title,
            message,
            cargo,
            nombre,
            departamento,
            fab,
            email,
            loading, showModalAlert,
            showModal, showAlert, type, cancelModal, saveBlock
        };
    },
    components: { Modal, TextField, Alert, ModalAlert },
});
