import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-40 z-50 flex justify-center items-center mt-auto"
}
const _hoisted_2 = { class: "flex flex-col w-96 max-w-full bg-white rounded-md p-6 justify-center items-center" }
const _hoisted_3 = { class: "flex justify-center" }
const _hoisted_4 = { class: "text-2xl font-bold mb-4 mt-5 text-gray-500 text-center" }
const _hoisted_5 = { class: "mb-4 font-bold mt-5 text-gray-400 text-center" }
const _hoisted_6 = { class: "flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_ModalAlertResolved = _resolveComponent("ModalAlertResolved")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_mdicon, {
              name: "alert-circle-outline",
              class: "text-orange-200",
              size: "150"
            })
          ]),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "default", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.message), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              class: "px-4 py-2 mr-3 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCancel && _ctx.handleCancel(...args)))
            }, "Cancelar"),
            _createElementVNode("button", {
              class: "mr-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleSuccess && _ctx.handleSuccess(...args)))
            }, "Aceptar ")
          ]),
          (_ctx.success)
            ? (_openBlock(), _createBlock(_component_ModalAlertResolved, {
                key: 0,
                type: _ctx.type,
                message: _ctx.messageSuccess,
                title: _ctx.titleSuccess,
                visible: _ctx.success,
                onClose: _ctx.handleCloseSuccess,
                onAccept: _ctx.handleAcceptSuccess
              }, null, 8, ["type", "message", "title", "visible", "onClose", "onAccept"]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}