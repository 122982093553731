
import { defineComponent, ref, toRef } from 'vue';
import Modal from '../../../general/molecules/Modal.vue'
import TextField from '../../../general/atoms/TextField.vue'
import Alert from '../../../general/atoms/Alert.vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import { FormService } from "@/services/form/FormServices";
import { findQuestionById } from '@/services/form/types';
import { useRoute } from 'vue-router';
export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        action: {
            type: String,
            default: '',
        },
        itemId: {
            type: Number,
            default: 0,
        }
    },
    setup(props, { emit }) {
        const route = useRoute();
        const communityId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const question = ref<string>('');
        const showModal = ref<boolean>(props.visible);
        const showAlert = ref<boolean>(false);
        const message = ref<string>('');
        const type = ref<string>('');
        const loading = ref<boolean>(false);
        const title = ref<string>('');
        const answerType = ref<number>(0);
        const answerTypeFront = ref<string>('');
        const index = ref<number>(0);
        const showModalAlert = ref<boolean>(false);
        const responseType = ref<number>();
        const formService = new FormService();
        const formId = ref<number>(2);

        valueUpdate();

        async function valueUpdate() {
            if (props.action == 'update') {
                const response = await formService.getFormQuestionService(2)
                const questions = response.data.questions
                const preguntaBuscada = findQuestionById(questions, props.itemId);
                if (preguntaBuscada) {
                    formId.value = preguntaBuscada.formId ?? 0
                    question.value = preguntaBuscada?.content ?? ''
                    answerTypeFront.value = preguntaBuscada?.answerTypeFront ?? ''
                    index.value = preguntaBuscada?.index ?? 0
                }
            }
        }

        function cancelModal() {
            showModal.value = false
            emit('update:visible', false);
            emit('closeAction');
        }

        function addOpenAlert() {
            cancelModal()
        }

        function validateForm() {
            if (!question.value) {
                return false
            } else {
                return true
            }
        }

        const generateAnswerType = () => {
            answerType.value = answerTypeFront.value === 'RADIAL' ? 0 : 3;
        }

        async function saveBlock() {
            generateAnswerType()
            loading.value = true
            const validate = validateForm()
            if (validate) {
                if (props.action == 'create') {
                    message.value = "Datos Guardados con Exito";
                    type.value = "success";
                    await formService.createFormQuestionService(formId.value,
                        question.value,
                        index.value,
                        answerType.value,
                        answerTypeFront.value)
                        loading.value = false
                    addOpenAlert()
                } else if (props.action == 'update') {
                    message.value = "Datos Editados con Exito";
                    type.value = "success";
                    await formService.updateFormQuestionService(props.itemId, formId.value,
                        question.value,
                        index.value,
                        answerType.value,
                        answerTypeFront.value)
                        loading.value = false
                    addOpenAlert()
                }

            } else {
                message.value = "Debe rellenar todos los campos";
                type.value = "warning";
                showAlert.value = true;
                loading.value = false
            }
            setTimeout(() => {
                showAlert.value = false;
            }, 4000);
        }

        return {
            title,
            message,
            question,
            responseType,
            answerTypeFront,
            loading, showModalAlert,
            index,
            showModal, showAlert, type, cancelModal, saveBlock
        };
    },
    components: { Modal, TextField, Alert, ModalAlert },
});
