
import { defineComponent, ref } from 'vue';
import Modal from '../../../general/molecules/Modal.vue'
import TextField from '../../../general/atoms/TextField.vue'
import Selector from '../../../general/atoms/Selector.vue'
import Alert from '../../../general/atoms/Alert.vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';

export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        action: {
            type: String,
            default: '',
        },
        itemId: {
            type: Number,
            default: 0,
        }
    },
    setup(props, { emit }) {

        const buildingTypes = [
            { value: 1, label: 'Solido' },
            { value: 2, label: 'Liquido' },
            { value: 3, label: 'Electrico' },
            { value: 4, label: 'Metales' },
            { value: 5, label: 'Grasas' }
        ]
        let tableData = ref([
            {
                id: 1,
                tipo: 1,
                descripcion: "esta es una descripcion del extintor",
                vencimiento: "2023-02-21",
                certificado: true,
                recargable: true
            },
            {
                id: 2,
                tipo: 2,
                descripcion: "esta es una descripcion del extintor",
                vencimiento: "2023-04-02",
                certificado: true,
                recargable: false
            },
            {
                id: 3,
                tipo: 3,
                descripcion: "esta es una descripcion del extintor",
                vencimiento: "2023-03-15",
                certificado: false,
                recargable: false
            }])
        const selectedItem = ref('');
        const tipo = ref();
        const descripcion = ref('');
        const vencimiento = ref('');
        const certificado = ref()
        const recargable = ref()
        const showModal = ref(props.visible);
        const showAlert = ref(false)
        const message = ref("");
        const type = ref("");
        const loading = ref(false);
        const showModalAlert = ref(false)
        const idItem = ref(props.itemId)

        valueUpdate();

        function valueUpdate() {
            if (props.action == 'update') {
                const getUser = tableData.value.filter((item) => item.id === idItem.value)
                tipo.value = getUser[0].tipo
                descripcion.value = getUser[0].descripcion
                vencimiento.value = getUser[0].vencimiento
                certificado.value = getUser[0].certificado
                recargable.value = getUser[0].recargable
            }
        }

        function cancelModal() {
            emit('update:visible', false);
            emit('update');
        }
        function onUpdateModel(value: any) {
            tipo.value = value;
        }
        function validateForm() {
            console.log('Guardando bloque:', { tipo: tipo.value, descripcion: descripcion.value, vencimiento: vencimiento.value, recargable: recargable.value, certificado: certificado.value });
            if (!tipo.value || !descripcion.value || !vencimiento.value || recargable.value == null || certificado.value == null) {
                return false
            } else {
                return true
            }
        }

        function addOpenAlert() {
            showModal.value = false
            showModalAlert.value = true
        }

        function saveExtinguisher() {
            loading.value = true
            const validate = validateForm()
            if (validate) {
                if (props.action == 'create') {
                    message.value = "Datos Guardados con Exito";
                    type.value = "success";
                    console.log('Guardando bloque:', { tipo: tipo.value, descripcion: descripcion.value, vencimiento: vencimiento.value, recargable: recargable.value, certificado: certificado.value });
                    addOpenAlert()
                } else if (props.action == 'update') {
                    message.value = "Datos Editados con Exito";
                    type.value = "success";
                    console.log('Guardando bloque:', { tipo: tipo.value, descripcion: descripcion.value, vencimiento: vencimiento.value, recargable: recargable.value, certificado: certificado.value });
                    addOpenAlert()
                }


            } else {
                message.value = "Debe rellenar todos los campos";
                type.value = "warning";
                showAlert.value = true;
                loading.value = false
            }
            setTimeout(() => {
                showAlert.value = false;
            }, 4000);
        }

        return {
            tipo,
            descripcion,
            recargable,
            selectedItem,
            vencimiento,
            certificado,
            loading,
            showModalAlert,
            buildingTypes,
            showModal, showAlert, type, message, cancelModal, saveExtinguisher, onUpdateModel

        };
    },

    components: { Modal, TextField, Alert, Selector, ModalAlert },
});
