import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "whitespace-normal text-sm sm:text-base" }
const _hoisted_2 = {
  key: 0,
  class: "border-solid border-2 border-red-400 mt-1 text-success-400 font-black"
}
const _hoisted_3 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["w-full sm:w-1/2 flex flex-col items-center justify-center select-none truncate", {
        'rounded-l-xl border-l-2': _ctx.roundLeft,
        'rounded-r-xl border-r-2': _ctx.roundRight,
        'font-bold bg-success text-white cursor-default': _ctx.selected,
        'font-bold cursor-pointer text-white': !_ctx.selected
    }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.select()))
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.content), 1),
    (_ctx.news && !_ctx.selected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("sub", _hoisted_3, _toDisplayString(_ctx.news), 1)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}