import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert-container"
}
const _hoisted_2 = {
  key: 0,
  class: "text-white flex items-center justify-between py-2 px-4 bg-green-400"
}
const _hoisted_3 = {
  key: 1,
  class: "text-white flex items-center justify-between py-2 px-4 bg-yellow-300"
}
const _hoisted_4 = {
  key: 2,
  class: "text-white flex items-center justify-between py-2 px-4 bg-red-400"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($props.type == 'success')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($props.message), 1))
          : ($props.type == 'warning')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.message), 1))
            : ($props.type == 'error')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($props.message), 1))
              : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}