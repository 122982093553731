
import { defineComponent, ref, watch, computed } from "vue";
import { CommunityService } from "@/services/communities/CommunityServices";
import Accordion from "@/components/general/atoms/Accordion.vue";
import { Query } from "@/services/communities/types";
import AddButton from "@/components/general/atoms/AddButton.vue";
import TextField from "@/components/general/atoms/TextField.vue";
import Modal from "@/components/general/molecules/Modal.vue";
import ModalAlert from "@/components/general/molecules/ModalAlert.vue";

export default defineComponent({
    props: {
        communityId: {
            type: Number,
            default: 0
        },
    },
    setup(props) {
        const communityService = new CommunityService();
        const formattedQueries = ref<{ title: string, content: string }[]>([]);
        const queries = ref<Query[]>([]);
        const showModalAdd = ref<boolean>(false);
        const showModalEdit = ref<boolean>(false);
        const showAceptAlert = ref<boolean>(false);
        const showEditAlert = ref<boolean>(false);
        const showAlertModal = ref<boolean>(false);
        const loading = ref<boolean>(false);
        const questionField = ref<string>("");
        const answerField = ref<string>("");
        const currentItem = ref<number>(0);

        const isFieldsFilled = computed(() => {
            return questionField.value.trim() !== '' && answerField.value.trim() !== '';
        });
        async function getQueries() {
            await communityService.getQueriesByCommunity(props.communityId)
                .then((response) => {
                    if (!response.success) {
                        queries.value = []
                        return;
                    }
                    else {
                        queries.value = response.data;
                        console.log(queries.value)
                        return queries
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        getQueries()
        watch(queries, () => {
            formattedQueries.value = queries.value.map((query) => {
                return {
                    title: query.question,
                    content: query.answer
                }
            })
        })
        async function addItem() {
            showModalAdd.value = true
            showAceptAlert.value = false
            answerField.value = ""
            questionField.value = ""
        }
        async function saveAddItem() {
            showModalAdd.value = false
            loading.value = true

            const queryWithoutId = {
                question: questionField.value,
                answer: answerField.value,
            }
            await communityService.createQueryByCommunity(props.communityId, queryWithoutId).then((response) => {
                if (!response.success) {
                    return;
                }
                if (response.code === 201) {
                    getQueries();
                    loading.value = false
                    showAceptAlert.value = true
                }
            })
        }
        async function editItem(index: number) {
            showModalEdit.value = true
            answerField.value = queries.value[index].answer
            questionField.value = queries.value[index].question
            currentItem.value = index
            showEditAlert.value = false
        }
        async function saveEditItem() {
            showModalEdit.value = false
            queries.value[currentItem.value].question = questionField.value
            queries.value[currentItem.value].answer = answerField.value
            const queryWithoutId = {
                question: queries.value[currentItem.value].question,
                answer: queries.value[currentItem.value].answer,
                community_id: props.communityId
            }
            await communityService.updateQuery(props.communityId, queryWithoutId, queries.value[currentItem.value].id).then((response) => {
                if (!response.success) {
                    return;
                }
                if (response.code === 200) {
                    getQueries()
                    showEditAlert.value = true
                }
            })
            currentItem.value = 0
        }
        async function deleteItem(index: number) {
            currentItem.value = index
            showAlertModal.value = true
        }
        async function handleAccept() {
            try {
                showAlertModal.value = false;
                await communityService.deleteQuery(props.communityId, queries.value[currentItem.value].id);
                currentItem.value = 0;
            } catch (error) {
                console.error(error);
            }
            await getQueries();
        }
        return {
            communityService,
            formattedQueries,
            queries,
            showModalAdd,
            showAceptAlert,
            showEditAlert,
            showModalEdit,
            showAlertModal,
            questionField,
            answerField,
            currentItem,
            editItem,
            saveEditItem,
            addItem,
            saveAddItem,
            deleteItem,
            handleAccept,
            isFieldsFilled,
            loading

        };
    },
    components: { Accordion, AddButton, TextField, Modal, ModalAlert }
})

