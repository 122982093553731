import { Response } from "../types";
import { NotificationBase } from "./types";
import { AuthenticatedService } from "../auth/AuthenticatedServices";
import {
    getResponse,
} from "../utils";
import { getEnvConfig } from "@/configs/UrlConfig";

export class NotificationServices extends AuthenticatedService {
    async getNotificationUser(receiverId: number): Promise<Response<NotificationBase[]>> {
        const url = `${getEnvConfig().apiURL}/notifications/${receiverId}/notification_by_user`;
        return getResponse(url, this.token, "GET");
    }

    async updateNotifications(
        receiverId: number,
        receiverIds: object,
    ) {
        const url = `${getEnvConfig().apiURL}/notifications/${receiverId}/edit`;
        return getResponse(url, this.token, "POST", receiverIds);
    }

}