
import { defineComponent, ref } from 'vue'
import TextField from '../atoms/TextField.vue';
import Modal from '../molecules/Modal.vue'
import ModalAlertResolved from '../molecules/ModalAlertResolved.vue';
import ComponentCard from '../molecules/ComponentCard.vue';
export default defineComponent({
    components: { TextField, Modal, ModalAlertResolved, ComponentCard },

    setup() {
        const showWriteMe = ref<boolean>(false)
        const showModalAlert = ref<boolean>(false)
        const loading = ref<boolean>(false)
        const name = ref<string>('')
        const phone = ref<string>('')
        const email = ref<string>('')
        const message = ref<string>('')

        const showWriteMeModal = () => {
            showWriteMe.value = true
        }

        const sendMail = () => {
            showModalAlert.value = false
            console.log(`Mensaje enviado ${name.value}, ${phone.value}. ${email.value}, ${message.value}`)
        }

        function openModalSend() {
            showWriteMe.value = false
            showModalAlert.value = true
        }

        return {
            showWriteMe,
            showModalAlert,
            showWriteMeModal,
            openModalSend,
            sendMail,
            loading,
            name,
            phone,
            email,
            message,
        }
    },
})

