
import AdminBar from '@/components/communities/organisms/AdminBar.vue';
import { defineComponent, onMounted } from 'vue';


export default defineComponent({
    props: {
        id: {
            type: String,
            default: '0',
        }
    },
    setup(props) {
        return;
    },
    components: { AdminBar }
})
