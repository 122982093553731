
import { defineComponent, ref, onMounted } from "vue";
import Modal from "@/components/general/molecules/Modal.vue";
import TextField from "@/components/general/atoms/TextField.vue";
import InputFile from "@/components/general/atoms/InputFile.vue";
import { AdressService } from '../../../../services/authExtra/addressServices';
import { BaseRegion, BaseProvinceRegion, BaseCountyProvince } from "../../../../services/authExtra/types";
import Alert from "@/components/general/atoms/Alert.vue";
import ModalAlert from "@/components/general/molecules/ModalAlert.vue";
import { CommunityService } from "@/services/communities/CommunityServices";
import { CreateCommunity } from "@/services/communities/types";
import { validateRut } from "@/services/utils";

export default defineComponent({

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        communeId: {
            type: Number,
            default: 0,
        },
        action: {
            type: String,
            default: 'create',
            require: true
        },
    },
    setup(props, { emit }) {

        const adressService = new AdressService()
        const communityService = new CommunityService();
        const showModal = ref(props.visible)
        const regions = ref<BaseRegion[]>()
        const provincies = ref<BaseProvinceRegion[]>()
        const couties = ref<BaseCountyProvince[]>()
        const selectedRegion = ref<number>(0)
        const selectedProvincie = ref<number>(0)
        const messageAlert = ref<string>("")
        const typeAlert = ref<string>("")
        const showAlert = ref<boolean>(false)
        const success = ref<boolean>(false)
        const itsCancel = ref<boolean>(false)
        const typeSuccess = ref<string>("")
        const titleSuccess = ref<string>("")
        const loading = ref<boolean>(false)
        const communityCreate = ref<CreateCommunity>(new CreateCommunity());

        onMounted(async () => {
            init()
        });

        const init = async () => {
            const response = await adressService.getAllRegions();
            regions.value = response.data;

        }

        const handleFile = (file: File) => {
            communityCreate.value.thumbnail = file
        }

        const getProvincesRegion = async () => {
            selectedProvincie.value = 0
            communityCreate.value.countyId = 0
            const response = await adressService.getProvinceRegion(selectedRegion.value);
            provincies.value = response.data;
        }

        const getCoutyProvince = async () => {
            const response = await adressService.getCoutyProvince(selectedProvincie.value);
            couties.value = response.data;
        }

        const validateForm = () => {
            if (communityCreate.value.rut &&
                communityCreate.value.address &&
                communityCreate.value.businessName &&
                communityCreate.value.name &&
                communityCreate.value.countyId &&
                communityCreate.value.description) {
                return true
            } else {
                return false
            }
        }

        const openModalAlert = () => {
            loading.value = false
            showModal.value = false
            success.value = true
        }

        const cancelCommune = () => {
            itsCancel.value = true
            showModal.value = false
            success.value = true
        }

        const saveCommune = async () => {
            loading.value = false
            const validador = validateForm()
            const rut: string = communityCreate.value.rut ?? "";
            const validarRut = validateRut(rut)
            if (validador) {
                if (validarRut) {
                    const response = await communityService.createCommunity(communityCreate.value)
                    openModalAlert()
                } else {
                    messageAlert.value = "Rut ingresado no cumple con el formato";
                    typeAlert.value = "error";
                    showAlert.value = true;
                }
            } else {
                messageAlert.value = "Debe rellenar todos los campos";
                typeAlert.value = "warning";
                showAlert.value = true;
            }
            setTimeout(() => {
                showAlert.value = false;
            }, 4000);

        }

        const handleAcceptSuccess = () => {
            success.value = false;
            emit('accept');
        };

        const handleCancelSuccess = () => {
            success.value = false;
            emit('accept');
        };

        const handleClose = () => {
            itsCancel.value = true
            showModal.value = false
            success.value = true
        };


        return {
            showModal,
            itsCancel,
            name,
            regions,
            provincies,
            couties,
            selectedRegion,
            selectedProvincie,
            getProvincesRegion,
            getCoutyProvince,
            saveCommune,
            cancelCommune,
            handleFile,
            handleClose,
            showAlert,
            messageAlert,
            typeAlert,
            success,
            typeSuccess,
            titleSuccess,
            handleAcceptSuccess,
            handleCancelSuccess,
            communityCreate,
            loading
        }
    },
    components: { Modal, TextField, InputFile, Alert, ModalAlert },

})
