
import Accordion from '@/components/general/atoms/Accordion.vue';
import TextField from '@/components/general/atoms/TextField.vue';
import ComponentCard from '@/components/general/molecules/ComponentCard.vue';
import { defineComponent, ref, computed, watch, onMounted } from 'vue';
import BlockFormQuestion from '../components/communities/molecules/BlockFormQuestion.vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import { AccessControlWithId } from '@/services/communities/types';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import AddButton from '@/components/general/atoms/AddButton.vue';
import Modal from '@/components/general/molecules/Modal.vue';
import { BlockCommunity } from "@/services/communities/types";
import { getEnvConfig } from "@/configs/UrlConfig";
import { CreateCommunity } from "@/services/communities/types";
import VueQrcode from 'vue-qrcode'

export default defineComponent({
    props: {
        blockId: {
            type: Number,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        },
        communityId: {
            type: Number,
            required: true
        },
    },
    emits: ['update:visible'],
    setup(props) {
        const TypeInstructions = [
            { value: 1, label: 'Incendio' },
            { value: 2, label: 'Terremoto' },
            { value: 3, label: 'Tsunami' },
            { value: 4, label: 'Evacuación' }
        ];
        const communityService = new CommunityService();
        const blockUpdate = ref<BlockCommunity>(new BlockCommunity());
        const formattedControlAccess = ref<{ title: string, content: string }[]>([]);
        const showModalEdit = ref<boolean>(false);
        const showModalAdd = ref<boolean>(false);
        const showAlertModal = ref<boolean>(false);
        const success = ref<boolean>(false);
        const loading = ref<boolean>(false);
        const nameField = ref<string>("");
        const descriptionField = ref<string>("");
        const currentItem = ref<number>(0);
        const controlAccess = ref<AccessControlWithId[]>([])
        const community = ref<CreateCommunity>();
        const date = ref()
        const isModified = ref<boolean>(false);
        const formattedInstructionsResident = ref<{ title: string, content: string }[]>([]);
        const formattedInstructionsPersonal = ref<{ title: string, content: string }[]>([]);
        const isFieldsFilled = computed(() => {
            return nameField.value.trim() !== '' && descriptionField.value.trim() !== '';
        });
        onMounted(async () => {
            community.value = (await communityService.getCommunity(props.communityId)).data
        })

        const showAceptAlert = ref<boolean>(false);
        const showEditAlert = ref<boolean>(false);
        const isDataEditable = ref<boolean>(false);
        const isFormEditable = ref<boolean>(false);
        onMounted(async () => {
            init()
        });

        const init = async () => {
            const response = await communityService.getBlock(props.communityId, props.blockId);
            const block = response.data
            if (block) {
                blockUpdate.value = block
            }
            getResidentInstructions()
            getPersonalInstructions()
        }


        async function getResidentInstructions() {
            const data = {
                instructionSetType: "RESIDENT"
            };
            for (let i = 1; i <= 4; i++) {
                const response = await communityService.getBlockIntructions(props.communityId, props.blockId, i, data);
                const contentArray = response.data.map((item) => item.instruction.content);
                const formattedLines = contentArray.map((content, index) => `${index + 1}: ${content}`);
                const formattedString = formattedLines.join('<br>');
                formattedInstructionsResident.value.push({
                    title: `Instrucciónes de ${TypeInstructions[i - 1].label}`,
                    content: formattedString
                });
            }
        }
        async function getPersonalInstructions() {
            const data = {
                instructionSetType: "PERSONNEL"
            }
            for (let i = 1; i <= 4; i++) {
                const response = await communityService.getBlockIntructions(props.communityId, props.blockId, i, data);
                const contentArray = response.data.map((item) => item.instruction.content);
                const formattedLines = contentArray.map((content, index) => `${index + 1}: ${content}`);
                const formattedString = formattedLines.join('<br>');
                formattedInstructionsPersonal.value.push({
                    title: `Instrucciónes de ${TypeInstructions[i - 1].label}`,
                    content: formattedString
                })
            }
        }

        async function getAccessControl() {
            try {
                const response = await communityService.getAcessControlByBlock(props.communityId, props.blockId);
                if (!response.success) {
                    controlAccess.value = [];
                }
                else {
                    controlAccess.value = response.data
                }
            }
            catch (error) {
                console.log(error);
            }
        }
        getAccessControl()
        watch(controlAccess, () => {
            formattedControlAccess.value = controlAccess.value.map((data) => {
                return {
                    title: data.name,
                    content: data.description
                }
            })
        })
        async function addItem() {
            showModalAdd.value = true
            showAceptAlert.value = false
            nameField.value = ""
            descriptionField.value = ""
        }
        async function saveAddItem() {
            showModalAdd.value = false
            const BaseAccessControl = {
                name: nameField.value,
                description: descriptionField.value,
            }

            await communityService.createAccessControlByBlock(props.communityId, props.blockId, BaseAccessControl).then((response) => {
                if (!response.success) {
                    return;
                }
                if (response.code === 201) {
                    getAccessControl();
                    showAceptAlert.value = true
                }
            })
        }
        async function editItem(index: number) {
            showModalEdit.value = true
            nameField.value = controlAccess.value[index].name
            descriptionField.value = controlAccess.value[index].description
            currentItem.value = index
            showEditAlert.value = false
        }
        async function saveEditItem() {
            showModalEdit.value = false
            loading.value = true
            controlAccess.value[currentItem.value].name = nameField.value
            controlAccess.value[currentItem.value].description = descriptionField.value
            const BaseAccessControl = {
                name: nameField.value,
                description: descriptionField.value,
            }
            await communityService.updateAccessControl(props.communityId, controlAccess.value[currentItem.value].id, BaseAccessControl).then((response) => {
                if (!response.success) {
                    return;
                }
                if (response.code === 200) {
                    loading.value = false
                    getAccessControl();
                    showEditAlert.value = true
                }
            })
            currentItem.value = 0
        }
        async function deleteItem(index: number) {
            currentItem.value = index
            showAlertModal.value = true
        }
        async function handleAccept() {
            try {
                showAlertModal.value = false;
                console.log("test", controlAccess.value[currentItem.value])
                await communityService.deleteAccessControl(props.communityId, controlAccess.value[currentItem.value].id)
                currentItem.value = 0;
            } catch (error) {
                console.error(error);
            }
            await getAccessControl();
        }
        const updateHandleBlock = async () => {
            await communityService.updateBlock(props.communityId, props.blockId, blockUpdate.value)
                .then((response) => {
                    if (response.code == 200) {
                        success.value = true
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
        }
        const handleAcceptSuccess = () => {
            success.value = false;
            isDataEditable.value = false;
            init()
        };

        return {
            isDataEditable,
            community,
            isFormEditable,
            formattedControlAccess,
            handleAcceptSuccess,
            updateHandleBlock,
            showModalEdit,
            showModalAdd,
            showAlertModal,
            nameField,
            descriptionField,
            currentItem,
            isFieldsFilled,
            showAceptAlert,
            showEditAlert,
            addItem,
            saveAddItem,
            editItem,
            saveEditItem,
            deleteItem,
            handleAccept,
            blockUpdate,
            success,
            date,
            getEnvConfig,
            formattedInstructionsResident,
            formattedInstructionsPersonal,
            isModified,
            loading

        };
    },
    components: {
        TextField, ComponentCard,
        BlockFormQuestion, Accordion, VueQrcode,
        ModalAlert, AddButton, Modal
    }
})
