

import { defineComponent, ref, onMounted, computed } from 'vue';
import { FormService } from "@/services/form/FormServices";
import { BaseForm, Question, Questionnaire } from '@/services/form/types';
import TextField from "@/components/general/atoms/TextField.vue";
import Selector from '../../general/atoms/Selector.vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import DetailsModalForm from './DetailsModalForm.vue';
import ComponentCard from '@/components/general/molecules/ComponentCard.vue';
export default defineComponent({
  props: {
    blockId: {
      type: Number,
      required: true
    },
    communityId: {
      type: Number,
      required: true
    },
  },
  setup(props) {
    const formsQuestions = ref<BaseForm>();
    const questionnaire = ref<Questionnaire>()
    const questions = ref<Question[]>([]);
    const formService = new FormService();
    const formTitle = ref<string>('');
    const tipo = ref<number>(1);
    const tipoSelector = ref<number>();
    const responseRadio = ref<boolean>();
    const title = ref<string>('');
    const message = ref<string>('');
    const visible = ref<boolean>(false);
    const showDetails = ref<boolean>(false);
    const indexDetails = ref<number>(0);
    const questionDetails = ref<Question>();
    const answers = ref<any[]>();
    const dataAnswer = computed(() => {
      return {
        answers: Array(questions.value.length).fill(''),
        answersDetailResident: Array(questions.value.length).fill([]),
        answersDetailPersonnel: Array(questions.value.length).fill([]),
        answersValueResident: Array(questions.value.length).fill([]),
        answersValuePersonnel: Array(questions.value.length).fill([]),
      }
    })
    const formSelector = ref([
      { value: 2, label: 'Formulario por defecto' },
    ])

    onMounted(async () => {
      init()
    });

    const handleAccept = () => {
      visible.value = false
    }
    const handleCloseSuccess = () => {
      visible.value = false
    }
    const init = async () => {
      const response = await formService.getFormQuestionService(2);
      const responseQuestionnaire = await formService.getQuestionnaireService(props.communityId, props.blockId);
      questionnaire.value = responseQuestionnaire.data
      formsQuestions.value = response.data;
      formTitle.value = formsQuestions.value.description;
      questions.value = response.data.questions.sort((a, b) => {
        if (a.index === undefined && b.index === undefined) {
          return 0;
        } else if (a.index === undefined) {
          return 1;
        } else if (b.index === undefined) {
          return -1;
        } else {
          return a.index - b.index;
        }
      });
      initQuestionnaire();
    }

    function onUpdateModel(value: any) {
      tipo.value = value;
      init()
    }
    function onUpdateSelector(value: number) {
      tipoSelector.value = Number(value);
    }
    function details(index: number, question: Question) {
      questionDetails.value = question
      indexDetails.value = index
      showDetails.value = true
    }
    const responseUpdate = (data: any, index: number) => {
      showDetails.value = false
      dataAnswer.value.answersDetailPersonnel[index] = data.answersDetailPersonnel.map((item: any) => {
        return item.content
      })
      dataAnswer.value.answersDetailResident[index] = data.answersDetailResident.map((item: any) => {
        return item.content
      })
      dataAnswer.value.answersValuePersonnel[index] = data.answersDetailPersonnel.map((item: any) => {
        return item.title
      })
      dataAnswer.value.answersValueResident[index] = data.answersDetailResident.map((item: any) => {
        return item.title
      })
    }
    function initQuestionnaire() {
              // si no funciona hacer por QUESTION ID 
              //TODO lo que hay que hacer es recorrer la lista de las preguntas y por 
              // id retornar la respuesta, necesito buscar la forma mas eficiente para hacer esto
              // 
      if(questionnaire.value && questionnaire.value.answers.length > 0){
         questionnaire.value.answers.map((answers) => {
          if (answers.contentBool) {
             answers.contentBool;
          }
          else if (answers.contentFloat) {
            return answers.contentFloat;
          }
          else if (answers.contentInt) {
            return answers.contentInt;
          }
          else if (answers.contentStr) {
            return answers.contentStr;
          }
          else {
            return null;
          }
        });
      }
    }
    async function saveForm() {
      answers.value = questions.value.map((question) => {
        if (question.responseForm === "true") {
          return true;
        }
        else if (question.responseForm === "false") {
          return false;
        }
        else if (!isNaN(Number(question.responseForm))) {
          return Number(question.responseForm);
        }
        else {
          return question.responseForm;
        }
      });
      const data = {
        answers: answers.value,
        answersDetailsResident: dataAnswer.value.answersDetailResident,
        answersParametersResident: dataAnswer.value.answersValueResident,
        answersDetailsPersonnel: dataAnswer.value.answersDetailPersonnel,
        answersParametersPersonnel: dataAnswer.value.answersValuePersonnel,
        answersValueResident: answers.value.map(value => value === true ? 1 : 0),
        answersValuePersonnel: answers.value.map(value => value === true ? 1 : 0)
      }
      console.log(data)
      formService.updateAnswerDetails(props.communityId, props.blockId, data)
    }

    return {
      title,
      visible,
      message,
      questions,
      formTitle,
      tipo,
      questionnaire,
      questionDetails,
      responseRadio,
      tipoSelector,
      formSelector,
      showDetails,
      indexDetails,
      dataAnswer,
      formsQuestions,
      onUpdateModel,
      onUpdateSelector,
      handleAccept,
      handleCloseSuccess,
      details,
      saveForm,
      responseUpdate,
answers,
    };

  },
  components: {  ComponentCard, TextField, Selector, ModalAlert, DetailsModalForm }
})
