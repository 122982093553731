
import { defineComponent, ref, onMounted } from 'vue';
import BlockCard from '../molecules/BlockCard.vue';
import NewBlockCard from '../molecules/NewBlockCard.vue';
import BlockView from '@/views/BlockView.vue';
import { CommunityService } from "@/services/communities/CommunityServices";
import { BlockCommunity } from "@/services/communities/types";
import SearchBox from '@/components/general/molecules/SearchBox.vue';

export default defineComponent({
    props: {
        communityId: {
            type: Number,
            default: 0,
        },
    },
    setup(props) {
        const showBlockView = ref<boolean>(false)
        const communityService = new CommunityService()
        const showModal = ref<boolean>(false)
        const blocksCommunity = ref<BlockCommunity[]>()
        const searchText = ref<string>('')
        const blockId = ref<number>(0)

        onMounted(async () => {
            getBlocks()
        });

        async function getBlocks() {
            const response = await communityService.getBlocksCommunity(props.communityId);
            blocksCommunity.value = response.data.filter((block: BlockCommunity) =>
                block.name && block.name.toLowerCase().includes(searchText.value.toLowerCase())
            );
        }

        getBlocks();

        const handleSearch = (event: InputEvent) => {
            const value = (event.target as HTMLInputElement).value;
            searchText.value = value;
            getBlocks();
        };

        const handleAccept = () => {
            getBlocks();
            showModal.value = false;
        };

        const deleteBlock = () => {
            getBlocks();
        }

        const showDetails = (id: any) => {
            blockId.value = id
            showBlockView.value = true
        }

        const reload = () => {
            getBlocks();
        }

        return {
            showBlockView, handleAccept, handleSearch, blocksCommunity, showDetails, deleteBlock, blockId, reload
        };
    },
    components: { BlockView, BlockCard, NewBlockCard, SearchBox }
})

